import { useEffect, useContext } from 'react'
import { useImmer } from "use-immer"
import {  Space, Table, Select, Input, Popconfirm, Checkbox, Tooltip, AutoComplete } from "antd"
import TextArea from "antd/es/input/TextArea"
import { MinusCircleOutlined, ExportOutlined, CheckCircleTwoTone, UnorderedListOutlined } from '@ant-design/icons'


function OfferExportRow(props) {

    const isRowVisible = props.fieldsState.liste[props.index].visible

    const [offerState, setOfferState] = useImmer({
        subcategoryList:[],
    })

    useEffect(() => {
        const selectedMainCategory = props.fieldsState.liste[props.index].category
        if (selectedMainCategory === null || selectedMainCategory === undefined) return
        const result = props.messageState.categoriesFetched
            .filter(item => item.def_type === "subcategory" && item.parent_id === selectedMainCategory)
            .map(item => ({ value: item.id, label: item.def_value }))
        setOfferState(draft => {
            draft.subcategoryList = result
        })
    }, [props.fieldsState.liste[props.index].category])

    const handleCategoryChange = (value) => {
        props.setFieldsState(draft => {
            draft.liste[props.index].category = value
            draft.liste[props.index].subcategory = null
        })
    }

    const handleSubCategoryChange = (value) => {
        props.setFieldsState(draft => {
            draft.liste[props.index].subcategory = value
        })
    }

    function fillAllRows(key, value) {
        for (let index = 0; index < props.fieldsState.liste.length; index++) {
            props.setFieldsState(draft => {
                draft.liste[index][key] = value
            })
        }
    }

    const addToCommonTexts = (key, value) => {
        props.setAcState(draft => {
            if (!draft.commonTexts[key].includes(value)) {
                draft.commonTexts[key].push(value)
            }
        })
    }

    const columns = [
        {
            title: 'Vendor',
            dataIndex: 'vendor',
            key: 'vendor',
            render: (_, record) => (
                <Space direction='horizontal'>
                    <AutoComplete
                        options={props.acState.options["vendor"].map((opt) => ({ value: opt.value }))}
                        value={record.vendor}
                        onSearch={(value) => {props.handleTextSearch("vendor", value)}}
                        onSelect={(value) => {props.setFieldsState(draft => {draft.liste[props.index].vendor = value})}}
                        onChange={(value) => {props.setFieldsState(draft => {draft.liste[props.index].vendor = value})}}
                    >
                        <Input style={{width:"200px", border: "2px solid red",}} placeholder=""
                            onBlur={() => { addToCommonTexts("vendor", record.vendor) }} />
                    </AutoComplete>
                    <Tooltip title="Fill all rows">
                        <UnorderedListOutlined style={{cursor:"pointer"}}
                            onClick={() => fillAllRows("vendor", props.fieldsState.liste[props.index].vendor)}/>
                    </Tooltip>
                </Space>
            )
        },
        {
            title: 'Main Category',
            dataIndex: 'category',
            key: 'category',
            render: (_, record) => (
                <Select style={{width:"190px", border: "2px solid red", borderRadius:8}} dropdownStyle={{ width: 250 }} placeholder=""
                    options={props.messageState.categoryList} value={record.category} onChange={handleCategoryChange}
                    showSearch optionFilterProp="label" disabled={props.messageState.isLoading}/>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'subcategory',
            key: 'subcategory',
            render: (_, record) => (
                <Select style={{width:"190px", border: "2px solid red", borderRadius:8}} dropdownStyle={{ width: 250 }} placeholder=""
                    options={offerState.subcategoryList} value={record.subcategory} onChange={handleSubCategoryChange}
                    showSearch optionFilterProp="label" disabled={props.messageState.isLoading} notFoundContent="Please select category first"/>
            ),
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            render: (_, record) => <TextArea rows={2} style={{width:"600px", resize:"none", border: "2px solid red",}} placeholder=""
                            value={record.model} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].model = e.target.value})}} />
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (_, record) => <Input style={{width:"75px", border: "2px solid red",}} placeholder=""
                            value={record.quantity} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].quantity = e.target.value})}} />
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            render: (_, record) => <Input style={{width:"75px", border: "2px solid red",}} placeholder=""
                            value={record.cost} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].cost = e.target.value})}} />
        },
        {
            title: 'EXW Offer',
            dataIndex: 'offer_exw',
            key: 'offer_exw',
            render: (_, record) => <Input style={{width:"75px", border: "2px solid red",}} placeholder=""
                            value={record.offer_exw} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].offer_exw = e.target.value})}} />
        },
        {
            title: 'Landed Offer',
            dataIndex: 'offer_landed',
            key: 'offer_landed',
            render: (_, record) => <Input style={{width:"100px"}} placeholder=""
                            value={record.offer_landed} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].offer_landed = e.target.value})}} />
        },
        {
            title: 'CNF Offer',
            dataIndex: 'offer_cnf',
            key: 'offer_cnf',
            render: (_, record) => <Input style={{width:"75px"}} placeholder=""
                            value={record.offer_cnf} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].offer_cnf = e.target.value})}} />
        },
        {
            title: 'Max Disc.',
            dataIndex: 'maxdiscount',
            key: 'maxdiscount',
            render: (_, record) => <Input style={{width:"75px"}} placeholder=""
                            value={record.maxdiscount} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].maxdiscount = e.target.value})}} />
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (_, record) => (
                <Space direction='horizontal'>
                    <AutoComplete
                        options={props.acState.options["location"].map((opt) => ({ value: opt.value }))}
                        value={record.location}
                        onSearch={(value) => {props.handleTextSearch("location", value)}}
                        onSelect={(value) => {props.setFieldsState(draft => {draft.liste[props.index].location = value})}}
                        onChange={(value) => {props.setFieldsState(draft => {draft.liste[props.index].location = value})}}
                    >
                        <Input style={{width:"125px", border: "2px solid red",}} placeholder=""
                            onBlur={() => { addToCommonTexts("location", record.location) }} />
                    </AutoComplete>
                    <Tooltip title="Fill all rows">
                        <UnorderedListOutlined style={{cursor:"pointer"}}
                            onClick={() => fillAllRows("location", props.fieldsState.liste[props.index].location)}/>
                    </Tooltip>
                </Space>
            )
        },
        {
            title: 'Nominal',
            dataIndex: 'nomianl',
            key: 'nomianl',
            render: (_, record) => <Input style={{width:"75px"}} placeholder=""
                            value={record.nomianl} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].nomianl = e.target.value})}} />
        },
        {
            title: 'Owned By',
            dataIndex: 'owner',
            key: 'owner',
            render: (_, record) => (
                <Space direction='horizontal'>
                    <AutoComplete
                        options={props.acState.options["owner"].map((opt) => ({ value: opt.value }))}
                        value={record.owner}
                        onSearch={(value) => {props.handleTextSearch("owner", value)}}
                        onSelect={(value) => {props.setFieldsState(draft => {draft.liste[props.index].owner = value})}}
                        onChange={(value) => {props.setFieldsState(draft => {draft.liste[props.index].owner = value})}}
                    >
                        <Input style={{width:"200px", border: "2px solid red",}} placeholder=""
                            onBlur={() => { addToCommonTexts("owner", record.owner) }} />
                    </AutoComplete>
                    <Tooltip title="Fill all rows">
                        <UnorderedListOutlined style={{cursor:"pointer"}}
                            onClick={() => fillAllRows("owner", props.fieldsState.liste[props.index].owner)}/>
                    </Tooltip>
                </Space>
            )
        },
        {
            title: 'ETA',
            dataIndex: 'eta',
            key: 'eta',
            render: (_, record) => (
                <Space direction='horizontal'>
                    <AutoComplete
                        dropdownStyle={{ width: 275 }}
                        options={props.acState.options["eta"].map((opt) => ({ value: opt.value }))}
                        value={record.eta}
                        onSearch={(value) => {props.handleTextSearch("eta", value)}}
                        onSelect={(value) => {props.setFieldsState(draft => {draft.liste[props.index].eta = value})}}
                        onChange={(value) => {props.setFieldsState(draft => {draft.liste[props.index].eta = value})}}
                    >
                        <Input style={{width:"125px", border: "2px solid red",}} placeholder=""
                            onBlur={() => { addToCommonTexts("eta", record.eta) }} />
                    </AutoComplete>
                    <Tooltip title="Fill all rows">
                        <UnorderedListOutlined style={{cursor:"pointer"}}
                            onClick={() => fillAllRows("eta", props.fieldsState.liste[props.index].eta)}/>
                    </Tooltip>
                </Space>
            )
        },
        {
            title: 'Prebook',
            dataIndex: 'prebook',
            key: 'prebook',
            align: 'center',
            width: '75px',
            render: (_, record) => (
                <Checkbox checked={record.prebook} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].prebook = e.target.checked})}} />
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => <Select style={{width:"225px"}} placeholder="" mode="tags"
                            options={props.messageState.actionList} value={record.actions} onChange={(value) => {props.setFieldsState(draft => {draft.liste[props.index].actions = value})}} />
        },
        {
            title: 'Act. Comp.',
            dataIndex: 'actions_completed',
            key: 'actions_completed',
            render: (_, record) => <Input style={{width:"125px"}} placeholder="" disabled
                            value={record.actions_completed} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].actions_completed = e.target.value})}} />
        },
        {
            title: 'Private Notes',
            dataIndex: 'note',
            key: 'note',
            render: (_, record) => <TextArea rows={2} style={{width:"260px", resize:"none"}} placeholder=""
                            value={record.note} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].note = e.target.value})}} />
        },
        {
            title: 'Sales Notes',
            dataIndex: 'sales_notes',
            key: 'sales_notes',
            render: (_, record) => (
                <Space direction='horizontal'>
                    <AutoComplete
                        options={props.acState.options["sales_notes"].map((opt) => ({ value: opt.value }))}
                        value={record.sales_notes}
                        onSearch={(value) => {props.handleTextSearch("sales_notes", value)}}
                        onSelect={(value) => {props.setFieldsState(draft => {draft.liste[props.index].sales_notes = value})}}
                        onChange={(value) => {props.setFieldsState(draft => {draft.liste[props.index].sales_notes = value})}}
                    >
                        <TextArea rows={2} style={{width:"240px", resize:"none"}} placeholder=""
                            onBlur={() => { addToCommonTexts("sales_notes", record.sales_notes) }} />
                    </AutoComplete>
                    <Tooltip title="Fill all rows">
                        <UnorderedListOutlined style={{cursor:"pointer"}}
                            onClick={() => fillAllRows("sales_notes", props.fieldsState.liste[props.index].sales_notes)}/>
                    </Tooltip>
                </Space>
            )
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            align: 'center',
            width: '75px',
            render: (_, record) => (
                <Checkbox checked={record.priority} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].priority = e.target.checked})}} />
            ),
        },
        {
            title: 'Featured',
            dataIndex: 'featured',
            key: 'featured',
            align: 'center',
            width: '75px',
            render: (_, record) => (
                <Checkbox checked={record.featured} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].featured = e.target.checked})}} />
            ),
        },
        {
            title: 'Bundle',
            dataIndex: 'bundle',
            key: 'bundle',
            render: (_, record) => <Input style={{width:"75px"}} placeholder=""
                            value={record.bundle} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].bundle = e.target.value})}} />
        },
        {
            title: 'Share',
            dataIndex: 'share',
            key: 'share',
            align: 'center',
            width: '75px',
            render: (_, record) => (
                <Checkbox checked={record.share} onChange={(e) => {props.setFieldsState(draft => {draft.liste[props.index].share = e.target.checked})}} />
            ),
        },
    ]

    const columnsGroup1 = columns.slice(0, 4)
    const columnsGroup2 = columns.slice(4, 15)
    const columnsGroup3 = columns.slice(15, 25)

    function deleteRow() {
        props.setFieldsState(draft => {
            draft.liste.splice(props.index, 1)
        })
    }

    function getRowColor() {
        return props.index % 2 ? "#6499c2" : "#7BB6E2"
    }

    return (
        <>
            <Space size="small">
                <div style={{height:isRowVisible?"250px":"34px", display:"flex", flexDirection:"col", alignItems:"center",
                    borderRadius:"4px", cursor:"pointer", padding:4, backgroundColor:getRowColor()}}
                    onClick={() => props.setFieldsState(draft => {draft.liste[props.index].visible = !props.fieldsState.liste[props.index].visible})}>
                    <strong style={{fontSize:"16px", }}>{props.index+1}</strong>
                    {isRowVisible ? "▲" : "▼"}
                </div>
                {isRowVisible ?
                <div key={props.index}>
                    <Table columns={columnsGroup1} dataSource={[props.fieldsState.liste[props.index]]} pagination={false} style={{paddingBottom:'6px'}}/>
                    <Table columns={columnsGroup2} dataSource={[props.fieldsState.liste[props.index]]} pagination={false} style={{paddingBottom:'6px'}}/>
                    <Table columns={columnsGroup3} dataSource={[props.fieldsState.liste[props.index]]} pagination={false} />
                </div> : 
                <div style={{width:"1237px", height:"30px", borderRadius:"4px", cursor:"pointer", padding:6, backgroundColor:getRowColor()}}
                    onClick={() => props.setFieldsState(draft => {draft.liste[props.index].visible = !props.fieldsState.liste[props.index].visible})}>
                    {props.fieldsState.liste[props.index].model || "--"}
                </div>}
                <Space direction="vertical" size={isRowVisible ? "large" : "small"}>
                    {/*props.index === 0*/ props.fieldsState.liste.length === 1 ? "" :
                        <Tooltip title="Delete Row">
                            <Popconfirm
                                title="Are you sure?"
                                //description=""
                                onConfirm={(e) => { deleteRow() }}
                                //onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <MinusCircleOutlined style={{fontSize:"24px", cursor:"pointer", color:"red"}} />
                            </Popconfirm>
                        </Tooltip>}
                        <Tooltip title="Export Row">
                            <ExportOutlined style={{fontSize:"24px", cursor:"pointer", color:"#73D673"}}
                                onClick={() => {props.exportFields(props.index)}}/>
                        </Tooltip>
                    {props.fieldsState.liste[props.index].exportIds.length === 0 ? "" :
                        <Tooltip title={props.fieldsState.liste[props.index].exportIds.join(", ")}>
                            <CheckCircleTwoTone style={{fontSize:"24px"}} twoToneColor="#52c41a" />
                        </Tooltip>
                    }
                </Space>
            </Space>
            <div style={{height:"16px",}}></div>
        </>
    )
}

export default OfferExportRow