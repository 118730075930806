import { useEffect, useContext } from 'react'
import { useNavigate, useLocation  } from 'react-router-dom'
import { useImmer } from "use-immer"
import { Alert, Form, Input, Button, Checkbox, Spin, Space } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import VerificationCodeInput from './VerificationCodeInput'
import { LOGIN_TYPE } from '../Settings'
//import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'
import MSLoginButton from './MSLoginButton'


function Login() {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [showError, setShowError] = useImmer(false)
    const [loginData, setLoginData] = useImmer({
        email:null,
        password:null,
        requestCount:0,
        isLoading: false,
    })
    const [loginState, setLoginState] = useImmer("login")

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (loginData.requestCount === 0) return

        async function login() {
            const email = loginData.email
            const password = loginData.password
            try {
                //const response = await Axios.post('token/login/', { email, password })
                const response = await api.post('token/login/', { email, password })
                //console.log(response.data)
                if (LOGIN_TYPE === "one-step") {
                    appDispatch({
                        type: "login",
                        data: response.data.auth_token,
                    })
                } else {
                    appDispatch({
                        type: "pre_login",
                        data: email,
                    })
                    setLoginState("code")
                }
            } catch (err) {
                //console.log(err)
                /*appDispatch({
                    type: "error",
                    data: "Unable to login!"
                })*/
                setShowError(true)
            }
            setLoginData(draft => {
                draft.isLoading = false
            })
        }
        login()
    }, [loginData.requestCount])

    function onFinish(values) {
        setLoginData(draft => {
            draft.email = values.email
            draft.password = values.password
            draft.requestCount++
            draft.isLoading = true
        })
    }

    function forgotPassword(e) {
        navigate('/forgot')
    }

    if (appState.loggedIn) {
        navigate('/')
    } else if (loginData.isLoading) {
        return (
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100vh'}}>
                <Spin size='large' />
            </div>
        )
    } else if (loginState === "code") {
        return (
            <VerificationCodeInput />
        )
    } else if (location.pathname !== "/jump") {
        return (
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100vh"}}>
                <MSLoginButton setMainLoginData={setLoginData} />
            </div>
        )
    }

    return (
        <>
            {showError && <div style={{display: 'block', width: '100%', textAlign: 'center'}}>
                <Alert message="Email address or password is incorrect!" type="error" />
            </div> }
            <Form
                name='login'
                className='login-form'
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <h3 style={{width:'100%', textAlign:'center', paddingBottom: '10px'}}>Welcome To DealCatcher</h3>
                <Form.Item
                    //htmlFor='email'
                    name='email'
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Please enter a valid email address!'
                        },
                    ]}
                >
                    <Input prefix={<MailOutlined className='site-form-item-icon' />} placeholder='Email' autoComplete="email"/>
                </Form.Item>
                <Form.Item
                    //htmlFor='password'
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your password!'
                        }
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined className='site-form-item-icon' />} placeholder='Password' visibilityToggle={true} autoComplete="current-password"/>
                </Form.Item>
                <Form.Item>
                    <Form.Item name='remember' valuePropName='checked' noStyle>
                        <Checkbox>Remember Me</Checkbox>
                    </Form.Item>
                    {/*<a className='login-form-forgot' onClick={forgotPassword}>
                        Forgot Password
                    </a>*/}
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button htmlType='submit' name='login' className='login-form-button btn-style' style={{width:"360px"}}>
                            Log in
                        </Button>
                        {/*<MSLoginButton setMainLoginData={setLoginData} />*/}
                    </Space>
                </Form.Item>
            </Form>
        </>
    )
}

export default Login
