import { useEffect, useContext, useRef } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useImmer } from "use-immer"
import { List, Button, Space, Alert, Tooltip, Tag, Spin, Avatar, Input, Select } from 'antd'
import { UserOutlined, PhoneOutlined, MailOutlined, FlagOutlined, BankOutlined, ShoppingOutlined, SkinOutlined, CommentOutlined, RedoOutlined, LoadingOutlined, ExportOutlined } from '@ant-design/icons'
import { cropText, formatPhoneNumber, getContactInfoAppendedMessageContent, getLocalTime, isValidEmail } from "../Utils"
import { useFetchCompanies, useFetchCountries } from "./DataFetcher"
import AntSelect from "./AntSelect"
import GenericWarningModal from "./GenericWarningModal"
import MessageContentModal from "./MessageContentModal"
import SideMenu from './SideMenu'
import Login from './Login'
import NotFound from "./NotFound"
import { NOT_AVAILABLE } from "../Constants"

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'


function ContactScreen(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const chatWindowRef = useRef(null)

    const { contactId } = useParams()
    const [navState, setNavState] = useImmer({navType: ""})
    const navigate = useNavigate()

    const [contactData, setContactData] = useImmer({isLoading:false, data:null, page:1, pageSize:100, requestCount:0, prevContainerHeight:0, isScroll:false})
    const [editableContactData, setEditableContactData] = useImmer({
        contactName:"", contactEmail:"", contactCompany:NOT_AVAILABLE, companyCountry:NOT_AVAILABLE, companyDefaultCountry:NOT_AVAILABLE,
        searchedCountry:"", requestCount:0, isLoading:false,
    })
    const [filterOptions, setFilterOptions] = useImmer({
        countries:{data:[], selected:[], excluded:[], requestCount:0, isLoading:false},
        companies:{data:[], selected:[], requestCount:0, isLoading:false},
    })
    const [genericWarningData, setGenericWarningData] = useImmer({title:"", modal: {isOpen: false}})
    const [messageContentData, setMessageContentData] = useImmer({title:"", content:"", msgId:-1, isModal:true, modal: {isOpen: false}})

    useFetchCountries(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchCompanies(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher

    useEffect(() => {
        const navType = performance.getEntriesByType("navigation")[0].type
        setNavState(draft => {
            draft.navType = navType
        })
    }, [navigate])

    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchContactInfo() {
            if (!appState.loggedIn) return
            setContactData(draft => {
                draft.isLoading = true
            })
            try {
                let url = 'get_contact_info/'
                url += `?page_size=${contactData.pageSize}`
                url += `&page=${contactData.page}`
                url += `&contact_id=${contactId}`

                const response = await api.get(
                    url, appState.token.get_config, { cancelToken: ourRequest.token }
                )
                //console.log(response.data)

                if (response.data.vendor) {
                    const vendor = response.data.vendor
                    setEditableContactData(draft => {
                        draft.contactName = vendor.full_name
                        draft.contactEmail = vendor.email
                        draft.contactCompany = vendor.company ? vendor.company.id : NOT_AVAILABLE
                        draft.companyCountry = vendor.company ? (vendor.company.country ? vendor.company.country.id : NOT_AVAILABLE) : NOT_AVAILABLE
                        draft.companyDefaultCountry = vendor.company ? (vendor.company.country ? vendor.company.country.id : NOT_AVAILABLE) : NOT_AVAILABLE
                    })
                }

                if (contactData.data === null) {
                    setContactData(draft => {
                        draft.data = response.data
                        draft.isScroll = true
                    })
                } else {
                    const cumMessages = response.data.messages.concat(contactData.data.messages)
                    setContactData(draft => {
                        draft.data.messages = cumMessages
                    })
                }
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to get contact info!"
                })
            } finally {
                setContactData(draft => {
                    draft.isLoading = false
                })
            }
        }
        fetchContactInfo()
        return () => {
            ourRequest.cancel()
        }
    }, [contactId, contactData.requestCount])

    useEffect(() => {
        if (!contactData.isScroll) return
        if (chatWindowRef === null || chatWindowRef.current === null) return
        if (contactData.requestCount === 0) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight
        } else {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight - contactData.prevContainerHeight
        }
        setContactData(draft => {
            draft.prevContainerHeight = chatWindowRef.current.scrollHeight
        })
    }, [contactData.data])

    useEffect(() => {
        if (editableContactData.contactCompany && editableContactData.contactCompany !== NOT_AVAILABLE) {
            const companyIndex = filterOptions.companies.data.findIndex((item) => editableContactData.contactCompany === item.id)
            let country = companyIndex === -1 ? null : filterOptions.companies.data[companyIndex].country
            if (country !== null)
                setEditableContactData(draft => {draft.companyCountry = country.id})
            else
                setEditableContactData(draft => {draft.companyCountry = NOT_AVAILABLE})
        } else {
            setEditableContactData(draft => {draft.companyCountry = NOT_AVAILABLE})
        }
    }, [editableContactData.contactCompany])

    useEffect(() => {
        if (editableContactData.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function updateContactOrUpdateCreateCompany() {
            setEditableContactData(draft => { draft.isLoading = true })
            let newCompanyId = editableContactData.contactCompany === NOT_AVAILABLE ? null : editableContactData.contactCompany

            // create new company
            if (editableContactData.searchedCountry !== "" && editableContactData.contactCompany === NOT_AVAILABLE) {
                const newCompanyData = {
                    'name': editableContactData.searchedCountry,
                    'country': editableContactData.companyCountry === NOT_AVAILABLE ? null : editableContactData.companyCountry
                }
                try {
                    const response = await api.post(
                        'list_companies/',
                        newCompanyData,
                        appState.token.post_config
                    )
                    //console.log(response.data)
                    appDispatch({
                        type: "success",
                        data: "New company created"
                    })
                    newCompanyId = response.data.id
                    setFilterOptions((draft) => {
                        draft.companies.data.push({
                            id:newCompanyId,
                            name:newCompanyData.name,
                            country:{id:editableContactData.companyCountry}
                        })
                    })
                    setEditableContactData(draft => {
                        draft.contactCompany = newCompanyId
                    })
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to create new company!"
                    })
                }
            }
            // update company
            // either a new name entered or country is changed
            else if(editableContactData.contactCompany !== NOT_AVAILABLE && (
                editableContactData.searchedCountry !== "" || editableContactData.companyDefaultCountry !== editableContactData.companyCountry
            )) {
                const newCompanyData = {
                    'name': editableContactData.searchedCountry === "" ? editableContactData.contactCompany.name : editableContactData.searchedCountry,
                    'country': editableContactData.companyCountry === NOT_AVAILABLE ? null : editableContactData.companyCountry
                }
                try {
                    const response = await api.patch(
                        `update_company/${editableContactData.contactCompany}/`,
                        newCompanyData,
                        appState.token.post_config
                    )
                    //console.log(response.data)
                    appDispatch({
                        type: "success",
                        data: "Company is updated"
                    })
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to update company!"
                    })
                }
            }

            const email = isValidEmail(editableContactData.contactEmail) ? editableContactData.contactEmail : null
            // update contact
            const newContactData = {
                'full_name': editableContactData.contactName,
                'email': email,
                'company': newCompanyId,
                'is_saved': true,
            }
            try {
                const response = await api.patch(
                    `update_vendor/${contactData.data.vendor.id}/`,
                    newContactData,
                    appState.token.post_config
                )
                //console.log(response.data)
                appDispatch({
                    type: "success",
                    data: "Contact is updated"
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to update contact!"
                })
            }
            setEditableContactData(draft => { draft.isLoading = false })
            /*setContactData(draft => {
                draft.data = null
                draft.requestCount++
            })*/
            //navigate(0) // Navigate to the same route
        }
        updateContactOrUpdateCreateCompany()
        return () => {
            ourRequest.cancel()
        }
    }, [editableContactData.requestCount])

    function get_product_model_names(mapping_list) {
        if (mapping_list.length === 0) return "Unknown"
        return (
            <div style={{maxHeight:"200px", width:"400px", overflowY:"auto"}}>
                {mapping_list.map((item, index) => (
                    <Tooltip key={index} title={item.product_model.name} placement="bottom">
                        <p key={item.product_model.name}>
                            {cropText(item.product_model.name, 35)}
                        </p>
                    </Tooltip>
                ))}
            </div>
        )
    }

    function get_common_groups(common_groups) {
        if (common_groups.length === 0) return "-"
        return (
            <div style={{maxHeight:"200px", width:"400px", overflowY:"auto"}}>
                {common_groups.map((item, index) => (
                    <Tooltip key={index} title={item} placement="bottom">
                        <p key={item}>
                            {cropText(item, 35)}
                        </p>
                    </Tooltip>
                ))}
            </div>
        )
    }

    let phoneNumber = contactData.data !== null ? contactData.data.vendor.phone_number : null
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber)
    if (formattedPhoneNumber !== null) phoneNumber = formattedPhoneNumber

    const commonGroupCount = contactData.data === null ? 0 : (contactData.data.common_groups === null ? 0 : contactData.data.common_groups.length)

    const vendor_data = [
        {
            icon: <UserOutlined />,
            title: 'Contact Name',
            value: (
                <Space >
                    <Input style={{width:"400px", }} placeholder=""
                        value={editableContactData.contactName} onInput={(e) => {setEditableContactData(draft => {draft.contactName = e.target.value})}} />
                    {/*<Tooltip title="reset"><RedoOutlined style={{cursor:"pointer"}} onClick={() => {setEditableContactData(draft => {draft.contactName = contactData.data.vendor.full_name})}}/></Tooltip>*/}
                </Space>
            )
        },
        {
            icon: <SkinOutlined />,
            title: 'Type',
            value: contactData.data === null ? "" : contactData.data.vendor_type,
        },
        {
            icon: <PhoneOutlined />,
            title: 'Phone Number',
            value: phoneNumber,
        },
        {
            icon: <FlagOutlined />,
            title: 'Country',
            value: contactData.data === null ? "" : contactData.data.vendor.country_names_from_area_code,
        },
        {
            icon: <MailOutlined />,
            title: 'E-mail',
            value: (
                <Space >
                    <Input style={{width:"400px", }} placeholder=""
                        value={editableContactData.contactEmail} onInput={(e) => {setEditableContactData(draft => {draft.contactEmail = e.target.value})}} />
                    {/*<Tooltip title="reset"><RedoOutlined style={{cursor:"pointer"}} onClick={() => {setEditableContactData(draft => {draft.contactEmail = contactData.data.vendor.email})}}/></Tooltip>*/}
                </Space>
            )
        },
        {
            icon: <BankOutlined />,
            title: 'Company',
            value: (
                <Space direction="vertical" size="middle">
                    <Input placeholder="Company Name (If company is selected its name is updated)"
                        value={editableContactData.searchedCountry}
                        onChange={(e) => {setEditableContactData(draft => {draft.searchedCountry = e.target.value})}} />
                    <AntSelect width={"400px"} placeholder="" showSearch={true}
                        options={filterOptions.companies.data}
                        selected={editableContactData.contactCompany}
                        onChange={(newValue) => {setEditableContactData(draft => {draft.contactCompany = newValue})}} />
                    <AntSelect width={"400px"} placeholder="" showSearch={true}
                        options={filterOptions.countries.data}
                        selected={editableContactData.companyCountry}
                        onChange={(newValue) => {setEditableContactData(draft => {draft.companyCountry = newValue})}} />
                    <Button type="primary" onClick={() => {setEditableContactData(draft => {draft.requestCount++})}}>
                        Update Contact/Company Data
                    </Button>
                </Space>
            )
        },
        {
            icon: <ShoppingOutlined />,
            title: 'Product Types',
            value: contactData.data === null ? "" : (
                contactData.data.product_name_mapping === null ? "" : get_product_model_names(contactData.data.product_name_mapping)
            ),
        },
        {
            icon: <CommentOutlined />,
            title: `Common Groups (${commonGroupCount})`,
            value: contactData.data === null ? "" : (
                contactData.data.common_groups === null ? "" : get_common_groups(contactData.data.common_groups)
            ),
        },
    ]

    const handleScroll = (e) => {
        /*if (e.target.scrollTop === 0) {} */
        setContactData(draft => {
            draft.isScroll = true
            draft.page++
            draft.requestCount++
        })
    }

    function getGroupNames(message) {
        return (
            <Tooltip title={message.group_names} placement="bottom">
                <p>
                    {cropText(message.group_names, 40)}
                </p>
            </Tooltip>
        )
    }

    function openMessageModel(message) {
        const record = {"vendor": contactData.data.vendor, "receivers": contactData.data.receivers, "message": message}
        const msgContent = getContactInfoAppendedMessageContent(record)
        setGenericWarningData(draft => {
            draft.title = msgContent
            draft.modal.isOpen = true
        })
    }

    function exportMessageToOffers(message) {
        const record = {"vendor": contactData.data.vendor, "receivers": contactData.data.receivers, "message": message}
        const msgContent = record ? getContactInfoAppendedMessageContent(record) : ""
        setMessageContentData(draft => {
            draft.title = msgContent
            draft.content = record.message.content
            draft.msgId = record ? record.message.id : -1
            draft.modal.isOpen = true
        })
    }

    const MAX_MSG_LEN = 300

    function toggleViewMsg(id) {
        const index = contactData.data.messages.findIndex((item) => id === item.id)
        const val = contactData.data.messages[index].showFullMsg
        setContactData(draft => {
            draft.isScroll = false
            draft.data.messages[index].showFullMsg = !val
        })
    }

    function getContentDisplayComp(message, content) {
        return (
            <div className="long-text-without-spaces-long-msg" style={{maxWidth:"unset", padding:5}} onClick={() => openMessageModel(message)}>
                {content}
            </div>
        )
    }

    function displayMessage(message) {
        const msgContent = message.content
        const msgLength = msgContent.length

        if (!message.showFullMsg && msgLength > MAX_MSG_LEN) {
            const croppedContent = cropText(msgContent, MAX_MSG_LEN/2)
            return (
                <>
                    {getContentDisplayComp(message, croppedContent)}
                    <span className='link-btn-style'
                        onClick={(e) => {
                            toggleViewMsg(message.id)
                        }}>
                        {"view more"}
                    </span>
                </>
            )
        }

        if (message.showFullMsg && msgLength > MAX_MSG_LEN) {
            return (
                <>
                    {getContentDisplayComp(message, msgContent)}
                    <span className='link-btn-style'
                        onClick={(e) => {
                            toggleViewMsg(message.id)
                        }}>
                        {"view less"}
                    </span>
                </>
            )
        }

        return getContentDisplayComp(message, msgContent)
    }

    /*if (document.referrer === "" || navState.navType !== "navigate") {
        return <NotFound />
    }*/

    function getBgColorOfListItem(index) {
        if (index % 2 === 0)
            return '#f0f0f0'
        if (index % 2 === 1)
            return '#fafafa'
        /*if (Math.floor(index / contactData.pageSize) % 2 === 0 && index % 2 === 0)
            return '#f0f0f0'
        if (Math.floor(index / contactData.pageSize) % 2 === 0 && index % 2 === 1)
            return '#fafafa'
        if (Math.floor(index / contactData.pageSize) % 2 === 1 && index % 2 === 0)
            return '#dcdcdc'
        if (Math.floor(index / contactData.pageSize) % 2 === 1 && index % 2 === 1)
            return '#e6e6e6'*/
    }

    if (!appState.loggedIn) return <Login />

    if (contactData.isLoading) {
        return <Spin style={{top:"50vh", left:"50vh", }} indicator={
            <LoadingOutlined
                style={{
                    fontSize: 192,
                }}
                spin
            />
          }/>
    }

    return (
        <div className='App'>
            <SideMenu connected={props.connected}/>
            <div style={{width:'12px'}}/>
            <div className="chat-window" ref={chatWindowRef} /*onScroll={handleScroll}*/>
                {(contactData.data !== null && (contactData.page * contactData.pageSize < contactData.data.count)) ? 
                    <Button style={{display:'block', marginLeft:'auto',}} type="link" onClick={handleScroll}>Load Older Messages</Button> : ""}
                <List
                    className="message-list"
                    itemLayout="horizontal"
                    dataSource={contactData.data === null ? [] : contactData.data.messages}
                    renderItem={(message, index) => (
                        <List.Item key={index} style={{
                                backgroundColor: getBgColorOfListItem(index),
                                borderRadius:4, 
                            }}>
                            <List.Item.Meta
                                avatar={<Space direction="vertical" size="small">
                                            <small style={{paddingLeft:6}}>
                                                <strong>
                                                    {`${contactData.data.count - (contactData.data.messages.length - index - 1)}/${contactData.data.count}`}
                                                </strong>
                                            </small>
                                            <Avatar
                                                style={{
                                                    backgroundColor: message.is_incoming ? '#f56a00' : '#87d068',
                                                    marginTop:-6, marginLeft:6
                                                }}
                                                //size="large"
                                            >
                                                {message.sender_name[0]}
                                            </Avatar>
                                        </Space>}
                                title={<Space>
                                    <div>{message.sender_name + " ➺ "}</div>
                                    {getGroupNames(message)}
                                    <Tooltip
                                        placement="right"
                                        title="Export Message to Offers"
                                    >
                                        <ExportOutlined
                                            style={{fontSize: '21px', color:'#ff7875', cursor:'pointer'}}
                                            onClick={() => exportMessageToOffers(message)}
                                        />
                                    </Tooltip>
                                </Space>}
                                description={<div>{displayMessage(message)}</div>}
                            />
                            <div className="chat-time" style={{paddingRight:6}}>
                                <strong>{getLocalTime(message.message_time)}</strong>
                            </div>
                        </List.Item>
                    )}
                />
            </div>
            {editableContactData.isLoading ? <Spin size="large" /> :
            <Space direction='horizontal' align='start' className='settings-panel'>
                <div>
                    <List style={{width: '400px', marginLeft: '15px'}}
                        dataSource={vendor_data}
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<div style={{paddingTop:'36px'}}>{item.icon}</div>}
                                    title={<p>{item.title}</p>}
                                    description={item.value}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </Space>}
            <GenericWarningModal
                state={genericWarningData}
                setState={setGenericWarningData}
                notDisplayOkButton={true}
                displayCopyButton={true}
            />
            <MessageContentModal
                state={messageContentData}
                setState={setMessageContentData}
                notDisplayOkButton={true}
                displayCopyButton={true}
            />
        </div>
    )
}

export default ContactScreen