import { useEffect, useContext } from "react"
import { Space, Spin, Collapse, Checkbox, Button, InputNumber, Input, Tooltip, Radio, Select, Tag, Divider } from "antd"
import { FilterOutlined, InfoCircleOutlined, BulbTwoTone, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import { useImmer } from "use-immer"
import AntSelect from "./AntSelect"
import WAGroupNames from "./WAGroupNames"
import ProductModelNames from "./ProductModelNames"
import ProductPartNumbers from "./ProductPartNumbers"
import ProductSpecs from "./ProductSpecs"
import VendorNames from "./VendorNames"
import SearchTipsModal from "./SearchTipsModal"
import { NORMAL_SEARCH } from '../Constants'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'
import { zip, sortAndAlignArrays, cropText, sortAndAlignArraysWithScores } from "../Utils"


const { Panel } = Collapse

function TableFilters(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [groupNameSearchState, setGroupNameSearchState] = useImmer({searchText: "", waGroupNames: [], loading: false, requestCount: 0})
    const [modelNameSearchState, setModelNameSearchState] = useImmer({searchText: "", productModelNames: [], loading: false, requestCount: 0})
    const [partNumberSearchState, setPartNumberSearchState] = useImmer({searchText: "", productPartNumbers: [], loading: false, requestCount: 0})
    const [specSearchState, setSpecSearchState] = useImmer({searchText: "", productSpecs: [], loading: false, requestCount: 0})
    const [vendorNameSearchState, setVendorNameSearchState] = useImmer({searchText: "", vendorNames: [], loading: false, requestCount: 0})
    const [brandModelsState, setBrandModelsState] = useImmer({jsx: <></>})

    const [searchTips, setSearchTips] = useImmer({modal: {isOpen: false}})

    useEffect(() => {
        if (groupNameSearchState.requestCount === 0) return

        const delay = setTimeout(() => {
            setGroupNameSearchState(draft => {
                draft.loading = true
            })
        }, 750)

        return () => clearTimeout(delay)
    }, [groupNameSearchState.requestCount])

    useEffect(() => {
        if (modelNameSearchState.requestCount === 0) return

        const delay = setTimeout(() => {
            setModelNameSearchState(draft => {
                draft.loading = true
            })
        }, 750)

        return () => clearTimeout(delay)
    }, [modelNameSearchState.requestCount])

    useEffect(() => {
        if (partNumberSearchState.requestCount === 0) return

        const delay = setTimeout(() => {
            setPartNumberSearchState(draft => {
                draft.loading = true
            })
        }, 750)

        return () => clearTimeout(delay)
    }, [partNumberSearchState.requestCount])

    useEffect(() => {
        if (specSearchState.requestCount === 0) return

        const delay = setTimeout(() => {
            setSpecSearchState(draft => {
                draft.loading = true
            })
        }, 750)

        return () => clearTimeout(delay)
    }, [specSearchState.requestCount])

    useEffect(() => {
        if (vendorNameSearchState.requestCount === 0) return

        const delay = setTimeout(() => {
            setVendorNameSearchState(draft => {
                draft.loading = true
            })
        }, 750)

        return () => clearTimeout(delay)
    }, [vendorNameSearchState.requestCount])

    function onDealTypeChange(id, e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (id === null) {
            props.setFilterOptions(draft => {
                draft.dealTypes.selected = []
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.dealTypes.selected.push(id)
            })
        } else {
            const index = props.filterOptions.dealTypes.selected.indexOf(id)
            props.setFilterOptions(draft => {
                draft.dealTypes.selected.splice(index, 1)
            })
        }
    }

    function onSourceTypeChange(id, e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (id === null) {
            props.setFilterOptions(draft => {
                draft.sourceTypes.selected = []
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.sourceTypes.selected.push(id)
            })
        } else {
            const index = props.filterOptions.sourceTypes.selected.indexOf(id)
            props.setFilterOptions(draft => {
                draft.sourceTypes.selected.splice(index, 1)
            })
        }
    }

    function onListConcealedMsgsChange(e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (e === null) {
            props.setFilterOptions(draft => {
                draft.isListConcealedMsgs = false
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.isListConcealedMsgs = true
            })
        } else {
            props.setFilterOptions(draft => {
                draft.isListConcealedMsgs = false
            })
        }
    }

    function onListEmptyUPCsChange(e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (e === null) {
            props.setFilterOptions(draft => {
                draft.isListEmptyUPCs = false
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.isListEmptyUPCs = true
            })
        } else {
            props.setFilterOptions(draft => {
                draft.isListEmptyUPCs = false
            })
        }
    }

    function onListSpammerItemsChange(e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (e === null) {
            props.setFilterOptions(draft => {
                draft.isListSpammerItems = false
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.isListSpammerItems = true
            })
        } else {
            props.setFilterOptions(draft => {
                draft.isListSpammerItems = false
            })
        }
    }

    function onExactMatchChange(e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (e === null) {
            props.setFilterOptions(draft => {
                draft.isExactMatch = false
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.isExactMatch = true
            })
        } else {
            props.setFilterOptions(draft => {
                draft.isExactMatch = false
            })
        }
    }

    function onProductPNKeywordsChange(value) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.productPNKeywords.selected = value
        })
    }
    
    function onMessageKeywordsChange(value) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.messageKeywords.selected = value
        })
    }

    function onListFavoritesOnlyChange(e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (e === null) {
            props.setFilterOptions(draft => {
                draft.isListFavoritesOnly = false
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.isListFavoritesOnly = true
            })
        } else {
            props.setFilterOptions(draft => {
                draft.isListFavoritesOnly = false
            })
        }
    }

    function onListFavoriteContactsOnlyChange(e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (e === null) {
            props.setFilterOptions(draft => {
                draft.isListFavoriteContactsOnly = false
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.isListFavoriteContactsOnly = true
            })
        } else {
            props.setFilterOptions(draft => {
                draft.isListFavoriteContactsOnly = false
            })
        }
    }

    function onWAGroupsChange(value) {
        props.setFilterOptions(draft => {
            draft.waGroupNames.data = []
        })
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.waGroupNames.selected = value
        })
    }

    function onModelNameChange(value) {
        props.setFilterOptions(draft => {
            draft.productModelNames.data = []
        })
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.productModelNames.selected = value
        })
    }

    function onPartNumberChange(value) {
        props.setFilterOptions(draft => {
            draft.productPartNumbers.data = []
        })
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.productPartNumbers.selected = value
        })
    }

    function onSpecChange(value) {
        props.setFilterOptions(draft => {
            draft.productSpecs.data = []
        })
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.productSpecs.selected = value
        })
    }

    function onSmallWAGroupsChange(e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (e === null) {
            props.setFilterOptions(draft => {
                draft.smallWAGroups = false
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.smallWAGroups = true
            })
        } else {
            props.setFilterOptions(draft => {
                draft.smallWAGroups = false
            })
        }
    }

    function onLargeWAGroupsChange(e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (e === null) {
            props.setFilterOptions(draft => {
                draft.largeWAGroups = false
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.largeWAGroups = true
            })
        } else {
            props.setFilterOptions(draft => {
                draft.largeWAGroups = false
            })
        }
    }

    function onProductModelChange(value) {
        if (value.length > 0 && !Number.isInteger(value.at(-1))) return
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.productModels.selected = value
        })
    }

    function onBrandChange(value) {
        if (value.length > 0 && !Number.isInteger(value.at(-1))) return
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.brands.selected = value
        })
    }

    function onCategoryChange(value) {
        if (value.length > 0 && !Number.isInteger(value.at(-1))) return
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.categories.selected = value
        })
    }

    function onSpecsChange(value) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (value.length === 1) {
            value = [value[0].toUpperCase()]
        } else if (value.length > 1) {
            const last_element = value[value.length - 1].toUpperCase()
            value.pop()
            if (!value.includes(last_element))
                value.push(last_element)
        }
        props.setFilterOptions(draft => {
            draft.specs.selected = value
        })
    }

    function onVendorsChange(value) {
        props.setFilterOptions(draft => {
            draft.vendors.data = []
        })
        //if (value.length > 0 && !Number.isInteger(value.at(-1))) return
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.vendors.selected = value
        })
    }

    function onCountriesChange(value) {
        if (value.length > 0 && !Number.isInteger(value.at(-1))) return
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.countries.selected = value
        })
    }
    /*function onCountriesSelect(value, option) {
        const label = option.label
        props.setFilterOptions(draft => {
            draft.countries_from_area_code.selected = value
        })
    }*/

    function onRegionsChange(value) {
        if (value.length > 0 && !Number.isInteger(value.at(-1))) return
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.regions.selected = value
        })
    }

    function onContactTagsChange(value) {
        //if (value.length > 0 && !Number.isInteger(value.at(-1))) return
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.contactTags.selected = value
        })
    }

    function onDealersChange(value) {
        if (value.length > 0 && !Number.isInteger(value.at(-1))) return
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.dealers.selected = value
        })
    }

    function onStatusChange(id, e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (id === null) {
            props.setFilterOptions(draft => {
                draft.statuses.selected = []
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.statuses.selected.push(id)
            })
        } else {
            const index = props.filterOptions.statuses.selected.indexOf(id)
            props.setFilterOptions(draft => {
                draft.statuses.selected.splice(index, 1)
            })
        }
    }

    function onStateChange(id, e) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (id === null) {
            props.setFilterOptions(draft => {
                draft.states.selected = []
            })
            return
        }
        if (e.target.checked) {
            props.setFilterOptions(draft => {
                draft.states.selected.push(id)
            })
        } else {
            const index = props.filterOptions.states.selected.indexOf(id)
            props.setFilterOptions(draft => {
                draft.states.selected.splice(index, 1)
            })
        }
    }

    function setMessageId(val) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (val === null) {
            props.setFilterOptions(draft => {
                draft.messageID = ""
            })
        } else {
            props.setFilterOptions(draft => {
                draft.messageID = val
            })
        }
    }

    /*function setContactNumber(val) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (val === "") {
            props.setFilterOptions(draft => {
                draft.contactNumber = ""
            })
        } else {
            props.setFilterOptions(draft => {
                draft.contactNumber = val
            })
        }
    }*/

    function onContactNumbersChange(value) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.contactNumbers.selected = value
        })
    }

    function setLastReachHours(val) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        if (val <= 720) {
            props.setFilterOptions(draft => {
                draft.lastReachHours = val
            })
        }
    }

    function onDBChange(val) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.db = val
        })
    }

    function onSearchMethodChange(val) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.searchMethod = val
        })
    }

    function onSearchTypeChange(val) {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = true
        })
        props.setFilterOptions(draft => {
            draft.productPNKeywords.searchType = val
        })
    }

    function filter() {
        props.setDataSource(draft => {
            draft.isThereFilterChanges = false
        })
        if (props.dataSource.pagination.current === 1) {
            props.setFilterOptions(draft => {
                draft.resetRowSpan = true
                draft.requestCount++
            })
        } else {
            props.setDataSource(draft => {
                draft.pagination.current = 1
            })
        }
    }

    function applyFilters() {
        //console.log(props.filterOptions)
        if (props.tableName === "Deal") {
            props.setDataSource(draft => {
                draft.isFetch = true
            })
        }
        filter()
    }

    function clearFilters() {
        if (props.tableName === "Deal") {
            onListEmptyUPCsChange(null)
            onSpecsChange([])
            
            onStatusChange(null, null)

            props.setDataSource(draft => {
                draft.isFetch = false
            })
        }

        if (props.tableName === "Message") {
            onExactMatchChange(null)
            onListFavoritesOnlyChange(null)
            onListFavoriteContactsOnlyChange(null)
            onMessageKeywordsChange([])
            onContactTagsChange([])
            onStateChange(null, null)

            props.setFilterOptions(draft => {
                draft.contactTags.excluded = []
            })

            onModelNameChange([])
            onPartNumberChange([])
            onSpecChange([])

            onSourceTypeChange(null, null)
            onProductPNKeywordsChange([])
        }

        onProductModelChange([])
        onBrandChange([])
        onCategoryChange([])
        
        onDealTypeChange(null, null)

        onWAGroupsChange([])
        onSmallWAGroupsChange(null)
        onLargeWAGroupsChange(null)

        onListSpammerItemsChange(null)
        
        onVendorsChange([])
        onCountriesChange([])
        onRegionsChange([])
        onDealersChange([])
        
        props.setFilterOptions(draft => {
            draft.countries.excluded = []
        })

        props.setFilterOptions(draft => {
            draft.regions.excluded = []
        })
        
        setMessageId(null)
        //setContactNumber("")
        onContactNumbersChange([])
        setLastReachHours(0)

        onDBChange(1)
        onSearchMethodChange(NORMAL_SEARCH)

        filter()
    }

    const handleCountryTagClick = (tag) => {
        let tempArr = [...props.filterOptions.countries.excluded]
        if (tempArr.includes(tag)) {
            tempArr = tempArr.filter((item) => item !== tag) // remove from excluded
            let tempArrSelected = [...props.filterOptions.countries.selected]
            tempArrSelected = tempArrSelected.filter((item) => item !== tag) // remove from selected
            props.setFilterOptions(draft => {
                draft.countries.selected = tempArrSelected
            })
        } else {
            tempArr.push(tag) // add to excluded
        }
        props.setFilterOptions(draft => {
            draft.countries.excluded = tempArr
        })
    }

    const customCountryTagRender = (propsTag) => {
        const { label, value, closable } = propsTag

        let textDecoration = 'none'
        if (props.filterOptions.countries.excluded.includes(value)) {
            textDecoration = 'line-through'
        }
    
        return (
            <div onClick={() => handleCountryTagClick(value)} className="custom-tag" style={{ textDecoration: textDecoration }}>
                {label}
            </div>
        )
    }

    const handleRegionTagClick = (tag) => {
        let tempArr = [...props.filterOptions.regions.excluded]
        if (tempArr.includes(tag)) {
            tempArr = tempArr.filter((item) => item !== tag) // remove from excluded
            let tempArrSelected = [...props.filterOptions.regions.selected]
            tempArrSelected = tempArrSelected.filter((item) => item !== tag) // remove from selected
            props.setFilterOptions(draft => {
                draft.regions.selected = tempArrSelected
            })
        } else {
            tempArr.push(tag) // add to excluded
        }
        props.setFilterOptions(draft => {
            draft.regions.excluded = tempArr
        })
    }

    const customRegionTagRender = (propsTag) => {
        const { label, value, closable } = propsTag

        let textDecoration = 'none'
        if (props.filterOptions.regions.excluded.includes(value)) {
            textDecoration = 'line-through'
        }
    
        return (
            <div onClick={() => handleRegionTagClick(value)} className="custom-tag" style={{ textDecoration: textDecoration }}>
                {label}
            </div>
        )
    }

    const handleContactNumberTagClick = (tag) => {
        let tempArr = [...props.filterOptions.contactNumbers.excluded]
        if (tempArr.includes(tag)) {
            tempArr = tempArr.filter((item) => item !== tag) // remove from excluded
            let tempArrSelected = [...props.filterOptions.contactNumbers.selected]
            tempArrSelected = tempArrSelected.filter((item) => item !== tag) // remove from selected
            props.setFilterOptions(draft => {
                draft.contactNumbers.selected = tempArrSelected
            })
        } else {
            tempArr.push(tag) // add to excluded
        }
        props.setFilterOptions(draft => {
            draft.contactNumbers.excluded = tempArr
        })
    }

    const customContactNumberTagRender = (propsTag) => {
        const { label, value, closable } = propsTag
    
        let textDecoration = 'none'
        if (props.filterOptions.contactNumbers.excluded.includes(value)) {
            textDecoration = 'line-through'
        }
    
        return (
            <div onClick={() => handleContactNumberTagClick(value)} className="custom-tag" style={{ textDecoration: textDecoration }}>
                {label}
            </div>
        )
    }

    const handleContactTagTagClick = (tag) => {
        let tempArr = [...props.filterOptions.contactTags.excluded]
        if (tempArr.includes(tag)) {
            tempArr = tempArr.filter((item) => item !== tag) // remove from excluded
            let tempArrSelected = [...props.filterOptions.contactTags.selected]
            tempArrSelected = tempArrSelected.filter((item) => item !== tag) // remove from selected
            props.setFilterOptions(draft => {
                draft.contactTags.selected = tempArrSelected
            })
        } else {
            tempArr.push(tag) // add to excluded
        }
        props.setFilterOptions(draft => {
            draft.contactTags.excluded = tempArr
        })
    }

    const customContactTagTagRender = (propsTag) => {
        const { label, value, closable } = propsTag

        let textDecoration = 'none'
        if (props.filterOptions.contactTags.excluded.includes(value)) {
            textDecoration = 'line-through'
        }
    
        return (
            <div onClick={() => handleContactTagTagClick(value)} className="custom-tag" style={{ textDecoration: textDecoration }}>
                {label}
            </div>
        )
    }

    function incOrExcTagBelowAbove(kw, key, score, incOrExc) {
        let newKeywordMap = {...props.filterOptions.productPNKeywords.keywordMap}
        let include = [...(newKeywordMap[kw]?.include || [])]
        let include_real = [...(newKeywordMap[kw]?.include_real || [])]
        let include_scores = [...(newKeywordMap[kw]?.include_scores || [])]
        let exclude = [...(newKeywordMap[kw]?.exclude || [])]
        let exclude_real = [...(newKeywordMap[kw]?.exclude_real || [])]
        let exclude_scores = [...(newKeywordMap[kw]?.exclude_scores || [])]

        if (incOrExc === "include") {
            // Handle inclusion
            const toIncludeIndices = []

            // Filter out items from exclude that have a higher score or lexicographically smaller names
            for (let i = 0; i < exclude.length; i++) {
                if (exclude_scores[i] > score || (exclude_scores[i] === score && exclude[i] <= key)) {
                    toIncludeIndices.push(i)
                }
            }

            const toInclude = toIncludeIndices.map(i => exclude[i])
            const toIncludeReal = toIncludeIndices.map(i => exclude_real[i])
            const toIncludeScores = toIncludeIndices.map(i => exclude_scores[i])

            exclude = exclude.filter((_, i) => !toIncludeIndices.includes(i))
            exclude_real = exclude_real.filter((_, i) => !toIncludeIndices.includes(i))
            exclude_scores = exclude_scores.filter((_, i) => !toIncludeIndices.includes(i))

            include.push(...toInclude)
            include_real.push(...toIncludeReal)
            include_scores.push(...toIncludeScores)
        } else if (incOrExc === "exclude") {
            // Handle exclusion
            const toExcludeIndices = []

            // Filter out items from include that have a lower score or lexicographically greater names
            for (let i = 0; i < include.length; i++) {
                if (include_scores[i] < score || (include_scores[i] === score && include[i] >= key)) {
                    toExcludeIndices.push(i)
                }
            }

            const toExclude = toExcludeIndices.map(i => include[i])
            const toExcludeReal = toExcludeIndices.map(i => include_real[i])
            const toExcludeScores = toExcludeIndices.map(i => include_scores[i])

            include = include.filter((_, i) => !toExcludeIndices.includes(i))
            include_real = include_real.filter((_, i) => !toExcludeIndices.includes(i))
            include_scores = include_scores.filter((_, i) => !toExcludeIndices.includes(i))

            exclude.push(...toExclude)
            exclude_real.push(...toExcludeReal)
            exclude_scores.push(...toExcludeScores)
        }

        newKeywordMap[kw] = {
            include,
            include_real,
            include_scores,
            exclude,
            exclude_real,
            exclude_scores
        }

        props.setFilterOptions(draft => {
            draft.productPNKeywords.keywordMap = newKeywordMap
        })
    }

    function incOrExcTag(kw, key, value, score, incOrExc) {
        let newKeywordMap = {...props.filterOptions.productPNKeywords.keywordMap}
        if (incOrExc === "include") {
            let exclude_scores = [...(newKeywordMap[kw]?.exclude_scores || [])]
            newKeywordMap[kw] = {
                ...newKeywordMap[kw],
                include: [...(newKeywordMap[kw]?.include || []), key],
                include_real: [...(newKeywordMap[kw]?.include_real || []), value],
                include_scores: [...(newKeywordMap[kw]?.include_scores || []), score],
                exclude: (newKeywordMap[kw]?.exclude || []).filter(item => item !== key),
                exclude_real: (newKeywordMap[kw]?.exclude_real || []).filter((item, index) => {
                    if (item === value) {
                        exclude_scores.splice(index, 1) // Remove the corresponding score by index
                        return false
                    }
                    return true
                }),
                exclude_scores: exclude_scores,
            }
        } else if (incOrExc === "exclude") {
            let include_scores = [...(newKeywordMap[kw]?.include_scores || [])]
            newKeywordMap[kw] = {
                ...newKeywordMap[kw],
                exclude: [...(newKeywordMap[kw]?.exclude || []), key],
                exclude_real: [...(newKeywordMap[kw]?.exclude_real || []), value],
                exclude_scores: [...(newKeywordMap[kw]?.exclude_scores || []), score],
                include: (newKeywordMap[kw]?.include || []).filter(item => item !== key),
                include_real: (newKeywordMap[kw]?.include_real || []).filter((item, index) => {
                    if (item === value) {
                        include_scores.splice(index, 1) // Remove the corresponding score by index
                        return false
                    }
                    return true
                }),
                include_scores: include_scores,
            }
        }

        props.setFilterOptions(draft => {
            draft.productPNKeywords.keywordMap = newKeywordMap
        })
    }

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj))
    }

    function getIncOrExcTag(key, _key, value, score, ttype) {
        const bgColor = ttype === "exclude" ? "#73D673" : "#ff7875"
        const caretColor = ttype === "exclude" ? "#ff7875" : "#73D673"
        return (
            <Space direction="horizontal" size="small" key={value + "_space"}>
                {ttype === "exclude" ?
                    <CaretDownOutlined style={{cursor:"pointer", backgroundColor:caretColor, padding:2, borderRadius:12, boxShadow: "0 2px 2px rgba(0, 0, 0, 0.2)"}}
                        onClick={() => incOrExcTagBelowAbove(key, _key, score, ttype)}/>
                :
                    <CaretUpOutlined style={{cursor:"pointer", backgroundColor:caretColor, padding:2, borderRadius:12, boxShadow: "0 2px 2px rgba(0, 0, 0, 0.2)"}}
                        onClick={() => incOrExcTagBelowAbove(key, _key, score, ttype)}/>
                }
                <div key={value} onClick={() => incOrExcTag(key, _key, value, score, ttype)}
                    className="custom-tag" style={{ /*textDecoration: 'none'*/ backgroundColor:bgColor }}>
                    {`${value} (${score.toFixed(2)})`}
                </div>
            </Space>
        )
    }

    useEffect(() => {
        //let bmMap = {...props.filterOptions.productPNKeywords.keywordMap}
        let bmMap = deepCopy(props.filterOptions.productPNKeywords.keywordMap)

        if (bmMap === null || bmMap === undefined || bmMap.length === 0) {
            setBrandModelsState(draft => {
                draft.jsx = <></>
            })
            return
        }

        const res = Object.keys(bmMap).map(key => {  // searched kw
            if ("part_number" in bmMap[key]) {
                const pn = bmMap[key]["part_number"]
                return (
                    <div key={key} style={{width:"200px"}}>
                        <Tooltip title={key}><Divider style={{fontSize:"12px"}}>{`${cropText(pn, 25)} (PN)`}</Divider></Tooltip>
                    </div>
                )
            } else {
                const [sortedIncludes, sortedIncludeReals, sortedIncludeScores] = sortAndAlignArraysWithScores(bmMap[key].include, bmMap[key].include_real, bmMap[key].include_scores)
                const [sortedExcludes, sortedExcludeReals, sortedExcludeScores] = sortAndAlignArraysWithScores(bmMap[key].exclude, bmMap[key].exclude_real, bmMap[key].exclude_scores)
                bmMap[key].include = sortedIncludes
                bmMap[key].include_real = sortedIncludeReals
                bmMap[key].include_scores = sortedIncludeScores
                bmMap[key].exclude = sortedExcludes
                bmMap[key].exclude_real = sortedExcludeReals
                bmMap[key].exclude_scores = sortedExcludeScores

                const included = zip(bmMap[key]["include"], bmMap[key]["include_real"], bmMap[key]["include_scores"])
                const included_tags = included.map(([_key, value, score]) => {
                    return (
                        getIncOrExcTag(key, _key, value, score, "exclude")
                    )
                })
                const excluded = zip(bmMap[key]["exclude"], bmMap[key]["exclude_real"], bmMap[key]["exclude_scores"])
                const excluded_tags = excluded.map(([_key, value, score]) => {
                    return (
                        getIncOrExcTag(key, _key, value, score, "include")
                    )
                })

                return (
                    <div key={key} style={{width:"200px"}}>
                        <Tooltip title={key}><Divider style={{fontSize:"12px"}}>{cropText(key, 25)}</Divider></Tooltip>
                        {included_tags}
                        {excluded_tags}
                    </div>
                )
            }
        })
        setBrandModelsState(draft => {
            draft.jsx = res
        })
        
    }, [props.filterOptions.productPNKeywords.keywordMap])

    return (
        <div style={{display: 'inline-block', marginRight: '0px'}}>
            <h3 style={{paddingLeft: '5px', marginTop: '19px', marginBottom: '12px'}}>Filter Options</h3>
            <Collapse
                size="small"
                bordered={false}
                defaultActiveKey={['1', '2', '3', '5', '6', '8']}
                style={{width: 244, fontSize: "13px", paddingLeft:'6px', backgroundColor:'#7bb6e2'}}
            >
                <Panel header="Deal Type" key="1">
                    {
                        props.filterOptions.dealTypes.data.map((dt) => {
                            return (
                                <Checkbox
                                    disabled={props.disabled}
                                    key={dt.id}
                                    onChange={(e) => { onDealTypeChange(dt.id, e) }}
                                    checked={props.filterOptions.dealTypes.selected.includes(dt.id)}
                                >
                                    <div style={{fontSize:'13px'}}>{dt.name}</div>
                                </Checkbox>
                            )
                        })
                    }
                </Panel>
                {props.tableName === "Message" && appState.user.role !== "GUEST" ?
                <Panel header="Source" key="2">
                    {
                        props.filterOptions.sourceTypes.data.map((dt) => {
                            return (
                                <Checkbox
                                    disabled={props.disabled}
                                    key={dt.id}
                                    onChange={(e) => { onSourceTypeChange(dt.id, e) }}
                                    checked={props.filterOptions.sourceTypes.selected.includes(dt.id)}
                                >
                                    <div style={{fontSize:'13px'}}>{dt.name}</div>
                                </Checkbox>
                            )
                        })
                    }
                </Panel>
                : ""}
                <Panel header="Message" key="3">
                    <Space direction="vertical">
                        {appState.user.role === "GUEST" ? "" :
                            <Checkbox
                                disabled={props.disabled}
                                onChange={(e) => { onListConcealedMsgsChange(e) }}
                                checked={props.filterOptions.isListConcealedMsgs}
                            >
                                {"List Concealed Msgs"}
                            </Checkbox>
                        }
                        {
                            <InputNumber
                                disabled={props.disabled || props.messageId !== undefined}
                                min={1}
                                controls={false}
                                type="number"
                                size="small"
                                placeholder="Message ID"
                                style={{width: '200px' }}
                                value={props.messageId === undefined ? props.filterOptions.messageID : props.messageId}
                                onChange={(val) => {setMessageId(val)}}
                                />
                        }
                        {
                            props.tableName === "Message" && appState.user.role !== "GUEST" ? <hr /> : ""
                        }
                        {
                            props.tableName === "Message" ?
                                props.filterOptions.productPNKeywords.isLoading || props.filterOptions.productPNKeywords.isLoading2 ?
                                <div style={{height:"200px", display:"flex", alignItems:"center", justifyContent:"center"}}><Spin size="large"/></div>
                                :
                                <Space direction="vertical">
                                    <Select
                                        disabled={props.disabled || props.filterOptions.productPNKeywords.isLoading}
                                        mode="tags"
                                        allowClear
                                        showSearch
                                        placeholder="Product/PN Search"
                                        size="large"
                                        options={[]}
                                        notFoundContent="Enter keywords"
                                        value={props.filterOptions.productPNKeywords.selected}
                                        onChange={onProductPNKeywordsChange}
                                        style={{width: 200}}/>
                                    <Radio.Group
                                        onChange={(e) => { onSearchTypeChange(e.target.value) }} value={props.filterOptions.productPNKeywords.searchType}
                                    >
                                        <Radio value={"product"}><div style={{fontSize: 12}}>product</div></Radio>
                                        <Radio value={"part-number"}><div style={{fontSize: 12}}>part-number</div></Radio>
                                    </Radio.Group>
                                    <div style={{maxHeight:"400px", overflowY:"auto"}}>
                                        {brandModelsState.jsx}
                                    </div>
                                </Space>
                                : ""
                        }
                        {
                            /*props.tableName === "Message" && appState.user.role !== "GUEST" ? <hr /> : ""*/
                        }
                        {
                            props.tableName === "Message" ? <Space direction="horizontal">
                                <Select
                                    disabled={props.disabled}
                                    mode="tags"
                                    allowClear
                                    showSearch
                                    placeholder="Search in messages"
                                    size="large"
                                    options={[]}
                                    notFoundContent="Enter keywords"
                                    value={props.filterOptions.messageKeywords.selected}
                                    onChange={onMessageKeywordsChange}
                                    style={{width: 200}}/>
                                <Space direction="vertical">
                                    <Tooltip title="Please use '&' between keywords for messages where keywords occuring together" placement="top">
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                    <SearchTipsModal
                                        state={searchTips}
                                        setState={setSearchTips}
                                    />
                                    <Tooltip title="Search tips" placement="bottom">
                                        <BulbTwoTone
                                            onClick={()=>setSearchTips(draft => {
                                                draft.modal.isOpen = true
                                            })}
                                            twoToneColor='#F8B501'
                                            style={{cursor:'pointer'}}
                                            />
                                    </Tooltip>
                                </Space>
                            </Space> : ""
                        }
                        {
                            props.tableName === "Message" ? <Checkbox
                                disabled={props.disabled}
                                onChange={(e) => { onExactMatchChange(e) }}
                                checked={props.filterOptions.isExactMatch}
                            >
                                {"Exact Match (elastic only)"}
                            </Checkbox> : ""
                        }
                        {
                            props.tableName === "Message" ? <Radio.Group
                                onChange={(e) => { onSearchMethodChange(e.target.value) }} value={props.filterOptions.searchMethod}>
                                <Radio value={"elastic"}><div style={{fontSize: 12}}>elastic</div></Radio>
                                <Radio value={"normal"}><div style={{fontSize: 12}}>normal</div></Radio>
                                <Radio value={"both"}><div style={{fontSize: 12}}>both</div></Radio>
                            </Radio.Group> : ""
                        }
                        {
                            props.tableName === "Message" && appState.user.role !== "GUEST" ? <hr /> : ""
                        }
                        {
                            props.tableName === "Message" && appState.user.role !== "GUEST" ? <Checkbox
                                disabled={props.disabled}
                                onChange={(e) => { onListFavoritesOnlyChange(e) }}
                                checked={props.filterOptions.isListFavoritesOnly}
                            >
                                {"Favorite messages only"}
                            </Checkbox> : ""
                        }
                        {
                            props.tableName === "Message" && appState.user.role !== "GUEST" ? <hr /> : ""
                        }
                        {appState.user.role === "GUEST" ? "" :
                        (props.filterOptions.waGroupNames.isLoading) ? <Spin size="small" /> :
                            <>
                                {/*<AntSelect
                                    //disabled={props.disabled}
                                    disabled={true}
                                    mode="tags"
                                    allowClear
                                    showSearch
                                    placeholder="Select Whatsapp Groups"
                                    size="small"
                                    options={props.filterOptions.waGroupNames.data}
                                    selected={props.filterOptions.waGroupNames.selected}
                                    onChange={onWAGroupsChange}
                                    width={200}/>*/}
                                <WAGroupNames
                                    disabled={props.disabled}
                                    options={props.filterOptions.waGroupNames.data}
                                    loading={groupNameSearchState.loading}
                                    value={props.filterOptions.waGroupNames.selected}
                                    onChange={onWAGroupsChange}
                                    onSearch={(newValue) => {
                                        if (newValue === undefined || newValue === null || newValue === "") {
                                            props.setFilterOptions(draft => {
                                                draft.waGroupNames.data = []
                                            })
                                            setGroupNameSearchState(draft => {
                                                draft.searchText = ""
                                                draft.loading = false
                                            })
                                        } else {
                                            setGroupNameSearchState(draft => {
                                                draft.searchText = newValue
                                                draft.requestCount++
                                            })
                                        }
                                    }}
                                    state={groupNameSearchState}
                                    setState={setGroupNameSearchState}
                                    setFilterOptions={props.setFilterOptions}
                                />
                            </>
                        }
                        {appState.user.role === "GUEST" ? "" :
                            <Space direction="horizontal" size="small">
                                <Checkbox
                                    disabled={props.disabled}
                                    onChange={(e) => { onSmallWAGroupsChange(e) }}
                                    checked={props.filterOptions.smallWAGroups}
                                >
                                    {"Small"}
                                </Checkbox>
                                <Checkbox
                                    disabled={props.disabled}
                                    onChange={(e) => { onLargeWAGroupsChange(e) }}
                                    checked={props.filterOptions.largeWAGroups}
                                >
                                    {"Large"}
                                </Checkbox>
                            </Space>
                        }
                    </Space>
                </Panel>
                {(props.tableName === "Deal" || props.tableName === "Message") && appState.user.role !== "GUEST" && false ?
                <Panel header="Product" key="4">
                    <Space direction="vertical">
                        {props.tableName === "Deal" ?
                            <Checkbox
                                disabled={props.disabled}
                                onChange={(e) => { onListEmptyUPCsChange(e) }}
                                checked={props.filterOptions.isListEmptyUPCs}
                            >
                                List Empty UPCs only
                            </Checkbox> : ""
                        }
                        {props.tableName === "Message" ?
                            <ProductModelNames
                                disabled={props.disabled}
                                options={props.filterOptions.productModelNames.data}
                                loading={modelNameSearchState.loading}
                                value={props.filterOptions.productModelNames.selected}
                                onChange={onModelNameChange}
                                onSearch={(newValue) => {
                                    if (newValue === undefined || newValue === null || newValue === "") {
                                        props.setFilterOptions(draft => {
                                            draft.productModelNames.data = []
                                        })
                                        setModelNameSearchState(draft => {
                                            draft.searchText = ""
                                            draft.loading = false
                                        })
                                    } else {
                                        setModelNameSearchState(draft => {
                                            draft.searchText = newValue
                                            draft.requestCount++
                                        })
                                    }
                                }}
                                state={modelNameSearchState}
                                setState={setModelNameSearchState}
                                setFilterOptions={props.setFilterOptions}
                            /> : ""
                        }
                        {(props.filterOptions.productModels.isLoading) ? <Spin size="small" /> :
                            <>
                                <AntSelect
                                    disabled={props.disabled}
                                    mode="tags"
                                    allowClear
                                    showSearch
                                    placeholder="Select Models"
                                    size="small"
                                    options={props.filterOptions.productModels.data}
                                    selected={props.filterOptions.productModels.selected}
                                    onChange={onProductModelChange}
                                    width={200}/>
                            </>
                        }
                        {(props.filterOptions.brands.isLoading) ? <Spin size="small" /> :
                            <>
                                <AntSelect
                                    disabled={props.disabled}
                                    mode="tags"
                                    allowClear
                                    showSearch
                                    placeholder="Select Brands"
                                    size="small"
                                    options={props.filterOptions.brands.data}
                                    selected={props.filterOptions.brands.selected}
                                    onChange={onBrandChange}
                                    width={200}/>
                            </>
                        }
                        {(props.filterOptions.categories.isLoading) ? <Spin size="small" /> :
                            <>
                                <AntSelect
                                    disabled={props.disabled}
                                    mode="tags"
                                    allowClear
                                    showSearch
                                    placeholder="Select Categories"
                                    size="small"
                                    options={props.filterOptions.categories.data}
                                    selected={props.filterOptions.categories.selected}
                                    onChange={onCategoryChange}
                                    width={200}/>
                            </>
                        }
                        {props.tableName === "Message" ?
                            <ProductPartNumbers
                                disabled={props.disabled}
                                options={props.filterOptions.productPartNumbers.data}
                                loading={partNumberSearchState.loading}
                                value={props.filterOptions.productPartNumbers.selected}
                                onChange={onPartNumberChange}
                                onSearch={(newValue) => {
                                    if (newValue === undefined || newValue === null || newValue === "") {
                                        props.setFilterOptions(draft => {
                                            draft.productPartNumbers.data = []
                                        })
                                        setPartNumberSearchState(draft => {
                                            draft.searchText = ""
                                            draft.loading = false
                                        })
                                    } else {
                                        setPartNumberSearchState(draft => {
                                            draft.searchText = newValue
                                            draft.requestCount++
                                        })
                                    }
                                }}
                                state={partNumberSearchState}
                                setState={setPartNumberSearchState}
                                setFilterOptions={props.setFilterOptions}
                            /> : ""
                        }
                        {props.tableName === "Message" ?
                            <ProductSpecs
                                disabled={props.disabled}
                                options={props.filterOptions.productSpecs.data}
                                loading={specSearchState.loading}
                                value={props.filterOptions.productSpecs.selected}
                                onChange={onSpecChange}
                                onSearch={(newValue) => {
                                    if (newValue === undefined || newValue === null || newValue === "") {
                                        props.setFilterOptions(draft => {
                                            draft.productSpecs.data = []
                                        })
                                        setSpecSearchState(draft => {
                                            draft.searchText = ""
                                            draft.loading = false
                                        })
                                    } else {
                                        setSpecSearchState(draft => {
                                            draft.searchText = newValue
                                            draft.requestCount++
                                        })
                                    }
                                }}
                                state={specSearchState}
                                setState={setSpecSearchState}
                                setFilterOptions={props.setFilterOptions}
                            /> : ""
                        }
                        {props.tableName === "Deal" ? <>
                            <AntSelect
                                disabled={props.disabled}
                                mode="tags"
                                allowClear
                                showSearch
                                placeholder="Specs"
                                size="small"
                                options={[]}
                                notFoundContent="Enter specs"
                                selected={props.filterOptions.specs.selected}
                                onChange={onSpecsChange}
                                width={200}/>
                        </> : ""}
                    </Space>
                </Panel> : ""}
                {appState.user.role === "GUEST" ? "" :
                <Panel header="Contact" key="5">
                    <Space direction="vertical">
                        <Checkbox
                            disabled={props.disabled}
                            onChange={(e) => { onListSpammerItemsChange(e) }}
                            checked={props.filterOptions.isListSpammerItems}
                        >
                            {props.tableName === "Deal" ? "List Deals from Spammers" : "List Msgs from Spammers"}
                        </Checkbox>
                        {/*<Input
                            disabled={props.disabled}
                            size="small"
                            placeholder="Contact Number"
                            style={{width: '200px' }}
                            value={props.filterOptions.contactNumber}
                            onChange={(e) => {setContactNumber(e.target.value)}}/>*/}
                        <Select
                            disabled={props.disabled}
                            mode="tags"
                            allowClear
                            showSearch
                            placeholder="Contact Numbers"
                            size="small"
                            tagRender={customContactNumberTagRender}
                            options={[]}
                            notFoundContent="Enter contact numbers"
                            value={props.filterOptions.contactNumbers.selected}
                            onChange={onContactNumbersChange}
                            style={{width: 200}}/>
                        {(props.filterOptions.vendors.isLoading) ? <Spin size="small" /> :
                            <>
                                {/*<AntSelect
                                    //disabled={props.disabled}
                                    disabled={true}
                                    mode="tags"
                                    allowClear
                                    showSearch
                                    placeholder="Select Contacts"
                                    size="small"
                                    options={props.filterOptions.vendors.data}
                                    selected={props.filterOptions.vendors.selected}
                                    onChange={onVendorsChange}
                                    width={200}/>*/}
                                <VendorNames
                                    disabled={props.disabled}
                                    options={props.filterOptions.vendors.data}
                                    loading={vendorNameSearchState.loading}
                                    value={props.filterOptions.vendors.selected}
                                    onChange={onVendorsChange}
                                    onSearch={(newValue) => {
                                        if (newValue === undefined || newValue === null || newValue === "") {
                                            props.setFilterOptions(draft => {
                                                draft.vendors.data = []
                                            })
                                            setVendorNameSearchState(draft => {
                                                draft.searchText = ""
                                                draft.loading = false
                                            })
                                        } else {
                                            setVendorNameSearchState(draft => {
                                                draft.searchText = newValue
                                                draft.requestCount++
                                            })
                                        }
                                    }}
                                    state={vendorNameSearchState}
                                    setState={setVendorNameSearchState}
                                    setFilterOptions={props.setFilterOptions}
                                />
                            </>
                        }
                        {(props.filterOptions.countries.isLoading) ? <Spin size="small" /> :
                            <>
                                <AntSelect
                                    disabled={props.disabled}
                                    mode="tags"
                                    allowClear
                                    showSearch
                                    placeholder="Select Countries"
                                    size="small"
                                    tagRender={customCountryTagRender}
                                    options={props.filterOptions.countries.data}
                                    selected={props.filterOptions.countries.selected}
                                    onChange={onCountriesChange}
                                    width={200}/>
                            </>
                        }
                        {(props.filterOptions.regions.isLoading) ? <Spin size="small" /> :
                            <>
                                <AntSelect
                                    disabled={props.disabled}
                                    mode="tags"
                                    allowClear
                                    showSearch
                                    placeholder="Select Regions"
                                    size="small"
                                    tagRender={customRegionTagRender}
                                    options={props.filterOptions.regions.data}
                                    selected={props.filterOptions.regions.selected}
                                    onChange={onRegionsChange}
                                    width={200}
                                    listHeight={512}/>
                                {/*props.tableName === "Message" ? <br /> : ""*/}
                            </>
                        }
                        {
                            props.tableName === "Message" ? <Checkbox
                                disabled={props.disabled}
                                onChange={(e) => { onListFavoriteContactsOnlyChange(e) }}
                                checked={props.filterOptions.isListFavoriteContactsOnly}
                            >
                                {"Favorite contacts only"}
                            </Checkbox> : ""
                        }
                        {
                            props.tableName === "Message" ?
                            (props.filterOptions.contactTags.isLoading) ? <Spin size="small" /> :
                                <>
                                    <AntSelect
                                        disabled={props.disabled}
                                        mode="tags"
                                        allowClear
                                        showSearch
                                        placeholder="Select Contact Tags"
                                        size="small"
                                        tagRender={customContactTagTagRender}
                                        options={props.filterOptions.contactTags.data}
                                        selected={props.filterOptions.contactTags.selected}
                                        onChange={onContactTagsChange}
                                        width={200}/>
                                </> : ""
                        }
                    </Space>
                </Panel>}
                {(props.tableName === "Deal" || props.tableName === "Message") && appState.user.role !== "GUEST" && appState.user.role !== "SALESMAN" && appState.user.role !== "BUYER" ?
                <Panel header={props.tableName === "Message" ? "Dealer (Direct Msg)" : "Dealer"} key="6">
                    {(props.filterOptions.dealers.isLoading) ? <Spin size="small" /> :
                        <>
                            <AntSelect
                                disabled={props.disabled}
                                mode="tags"
                                allowClear
                                showSearch
                                placeholder="Select Dealers"
                                size="small"
                                options={props.filterOptions.dealers.data}
                                selected={props.filterOptions.dealers.selected}
                                onChange={onDealersChange}
                                width={200}/>
                        </>
                    }
                </Panel> : "" }
                {(props.tableName === "Deal" || (props.tableName === "Message" && appState.user.role === "DATA MANAGER")) && appState.user.role !== "GUEST" ?
                <Panel header="Status" key="7">
                    <Space direction="vertical" size="small">
                        {props.tableName === "Deal" ?
                            props.filterOptions.statuses.data.map((st) => {
                                return (
                                    <Checkbox
                                        disabled={props.disabled}
                                        key={st[0]}
                                        onChange={(e) => { onStatusChange(st[0], e) }}
                                        checked={props.filterOptions.statuses.selected.includes(st[0])}
                                    >
                                        <div style={{fontSize:'14px'}}>{st[1]}</div>
                                    </Checkbox>
                                )
                            }) : ""
                        }
                        {props.tableName === "Message" && appState.user.role === "DATA MANAGER" ?
                            props.filterOptions.states.data.map((st) => {
                                return (
                                    <Checkbox
                                        disabled={props.disabled}
                                        key={st[0]}
                                        onChange={(e) => { onStateChange(st[0], e) }}
                                        checked={props.filterOptions.states.selected.includes(st[0])}
                                    >
                                        <div style={{fontSize:'14px'}}>{st[1]}</div>
                                    </Checkbox>
                                )
                            }) : ""
                        }
                    </Space>
                </Panel> : ""}
                {props.tableName === "Message" && appState.user.role !== "GUEST" ?
                <Panel header="Reach out" key="8">
                    <Space direction="vertical">
                        <div>
                            {"Only list contacts not reached in the last "}
                            <Input
                                disabled={props.disabled}
                                size="small"
                                placeholder=""
                                style={{width: '40px' }}
                                value={props.filterOptions.lastReachHours}
                                onChange={(e) => {setLastReachHours(e.target.value)}}
                                />
                            {" hours"}
                        </div>
                    </Space>
                </Panel> : ""}
                {/*<Panel header="DB" key="9">
                    <Space direction="vertical" size="small">
                        {
                            <Radio.Group onChange={(e) => { onDBChange(e.target.value) }} value={props.filterOptions.db}>
                                <Radio value={1}>small</Radio>
                                <Radio value={2}>large</Radio>
                            </Radio.Group>
                        }
                    </Space>
                </Panel>*/}
            </Collapse>
            <Button
                disabled={props.disabled}
                className='btn-style'
                style={{float: 'right', marginTop: '10px', marginRight: '2px', marginBottom:'15px'}}
                icon={<FilterOutlined />}
                onClick={()=>applyFilters()}
            >
                    Apply
            </Button>
            <Button
                disabled={props.disabled}
                style={{float: 'right', marginTop: '10px', marginBottom:'15px'}}
                type="link"
                onClick={()=>clearFilters()}
            >
                    Clear All
            </Button>
        </div>
    )
}

export default TableFilters
