import { useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useImmerReducer } from "use-immer"
import { useImmer } from "use-immer"
import { notification } from "antd"
import { io } from 'socket.io-client'
import React from 'react'

import Header from './components/Header'
import DealsTable from './components/DealsTable'
import MessagesTable from "./components/MessagesTable"
import ContactScreen from "./components/ContactScreen"
import ManageMappingsTable from "./components/ManageMappingsTable"
import FileUploadScreen from "./components/FileUploadScreen"
import ReachScreen from "./components/ReachScreen"
import MappingsTable from "./components/MappingsTable"
import ChatLinksTable from "./components/ChatLinksTable"
//import ContactsTable from "./components/ContactsTable"
import WhatsApp from "./components/WhatsApp"
import WhatsAppAdmin from "./components/WhatsAppAdmin"
import Settings from "./components/Settings"
import Test from "./components/Test"
import Forgot from "./components/Forgot"
import ResetPassword from "./components/ResetPassword"
import NotFound from "./components/NotFound"

import StateContext from "./StateContext"
import DispatchContext from "./DispatchContext"

import { getLocalTime } from './Utils'

import Axios from "axios"
import api from "./AxiosInstance"
import { setCustomLogoutFunction } from "./auth"

import KvkkModal from "./components/KvkkModal"

import { SOCKET_URL, SOCKET_PATH, NODES } from "./Settings"
import PurchasingScreen from "./components/PurchasingScreen"
import Login from "./components/Login"


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {error: ""}
    }
  
    componentDidCatch(error) {
        //console.log(error)
        this.setState({error: `${error.name}: ${error.message}`})
        const dispatch = this.props.dispatchContext
        dispatch({
            type: "error",
            data: String(error.message)
        })
    }
  
    render() {
        const {error} = this.state
        if (error) {
            return (
                <div>{error}</div>
            )
        } else {
            return <>{this.props.children}</>
        }
    }
}

let socket = null

function App() {

    const initialState = {
        loggedIn: Boolean(localStorage.getItem("dc_token")),
        user: {
            username: localStorage.getItem("dc_username"),
            email: localStorage.getItem("dc_email"),
            phonenumber: localStorage.getItem("dc_phonenumber"),
            isstaff: localStorage.getItem("dc_isstaff"),
            role: localStorage.getItem("dc_role"),
            kvkk_sign_date: localStorage.getItem("dc_kvkk_sign_date"),
            node: localStorage.getItem("dc_node"),
        },
        token: {
            value: localStorage.getItem("dc_token"),
            get_config: {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem("dc_token")}`,
                    'Accept': 'application/json'
                }
            },
            get_file_config: {
                headers: {
                    'Authorization': `Token ${localStorage.getItem("dc_token")}`,
                },
                responseType: 'blob',
            },
            post_config: {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem("dc_token")}`,
                }
            },
            post_file_config: {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Token ${localStorage.getItem("dc_token")}`,
                }
            },
        },
        message: "",
        err:null,
        infoCounter: 0,
        errorCounter: 0,
        successCounter: 0,
        requestCounter: 0,
        latestMessageDates: null,
    }
    
    function ourReducer(draft, action) {
        switch (action.type) {
            case "pre_login":
                localStorage.setItem("dc_email", action.data)
                return
            case "login":
                draft.loggedIn = true
                draft.token.value = action.data
                draft.token.get_config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${action.data}`,
                        'Accept': 'application/json'
                    }
                }
                draft.token.post_config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${action.data}`,
                    }
                }
                return
            case "user":
                draft.user = action.data
                localStorage.setItem("dc_username", action.data.username)
                localStorage.setItem("dc_email", action.data.email)
                localStorage.setItem("dc_phonenumber", action.data.phonenumber)
                localStorage.setItem("dc_isstaff", action.data.isstaff)
                localStorage.setItem("dc_role", action.data.role)
                localStorage.setItem("dc_kvkk_sign_date", action.data.kvkk_sign_date)
                localStorage.setItem("dc_node", action.data.node)
                draft.requestCounter++
                return
            case "logout":
                draft.loggedIn = false
                return
            case "info":
                draft.message = action.data
                draft.infoCounter++
                return
            case "error":
                draft.message = action.data
                draft.err = action.err
                draft.errorCounter++
                return
            case "success":
                draft.message = action.data
                draft.successCounter++
                return
            case "latestMessageDates":
                draft.latestMessageDates = action.data
                return
        }
    }

    const [state, dispatch] = useImmerReducer(ourReducer, initialState)

    useEffect(() => {
        if (state.loggedIn) {
            localStorage.setItem("dc_token", state.token.value)
            /*localStorage.setItem("dc_username", state.user.username)
            localStorage.setItem("dc_email", state.user.email)
            localStorage.setItem("dc_phonenumber", state.user.phonenumber)
            localStorage.setItem("dc_isstaff", state.user.isstaff)
            localStorage.setItem("dc_role", state.user.role)
            localStorage.setItem("dc_kvkk_sign_date", state.user.kvkk_sign_date)
            localStorage.setItem("dc_node", state.user.node)*/
        } else {
            localStorage.removeItem("dc_token")
            localStorage.removeItem("dc_username")
            localStorage.removeItem("dc_email")
            localStorage.removeItem("dc_phonenumber")
            localStorage.removeItem("dc_isstaff")
            localStorage.removeItem("dc_role")
            localStorage.removeItem("dc_kvkk_sign_date")
            localStorage.removeItem("dc_node")
        }
    }, [state.loggedIn])

    function customLogout() {
        dispatch({type: "logout", })
        window.location.reload(true)
    }

    useEffect(() => {
        setCustomLogoutFunction(customLogout)
    }, [])

    function updateFavicon(iconPath) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement("link")
        link.type = "image/x-icon"
        link.rel = "shortcut icon"
        link.href = iconPath
        document.head.appendChild(link)
    }

    function displayNotification(message) {
        if ("Notification" in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    new Notification(message)
                    //updateFavicon('favicon-32x32.png')
                }
            })
        }
    }

    function displayOfferNotification(messageId, senderName, content) {
        if ("Notification" in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    const options = {
                        body: content,
                        icon: "icon.png",
                        // You can also add more options like `vibrate`, `badge`, `image`, `tag`, `renotify`, etc.
                    }
            
                    const notification = new Notification(senderName, options)
            
                    notification.onclick = function(event) {
                        event.preventDefault() // Prevent the browser from focusing the Notification's tab
                        window.open(`https://dealdt.com/reaches/${messageId}`, "_blank")
                    }
                }
            })
        }
    }

    const POLLING_INTERVAL_FIRE = 60000

    const fetchFireCheckData = async () => {
        if (!state.loggedIn || state.user.role === "GUEST") return
        try {
            const response = await api.get('fire_check/', state.token.get_config)
            response.data.map((item) => {
                displayOfferNotification(item.message.id, item.custom_user.full_name, item.message.content)
            })
        } catch (error) {
            console.error('Error fetching fire check', error)
        }
    }

    useEffect(() => {
        if (!state.loggedIn) return
        fetchFireCheckData()
        const intervalId = setInterval(fetchFireCheckData, POLLING_INTERVAL_FIRE)

        return () => clearInterval(intervalId) // Clean up on unmount
    }, [])


    function displayWhatsAppConnNotification(content) {
        if ("Notification" in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    const options = {
                        body: content,
                        icon: "icon.png",
                        // You can also add more options like `vibrate`, `badge`, `image`, `tag`, `renotify`, etc.
                    }
            
                    const notification = new Notification("", options)
            
                    notification.onclick = function(event) {
                        event.preventDefault() // Prevent the browser from focusing the Notification's tab
                        window.open('https://dealdt.com/whatsapp', "_blank")
                    }
                }
            })
        }
    }

    const POLLING_INTERVAL_MSG = 300000  // 5mins

    const fetchLatestMessageData = async () => {
        if (!state.loggedIn || state.user.role === "GUEST" || state.user.role === "OTHER"/* || state.user.role === "DATA MANAGER"*/) return
        try {
            const response = await api.get('check_latest_message_time/', state.token.get_config)
            const latestMessageDate = new Date(response.data["latest_message_date"])

            const currentDate = new Date()
            let difference = currentDate - latestMessageDate  // difference in milliseconds
            let differenceInMinutes = difference / (1000 * 60)

            if (differenceInMinutes > 15) {
                differenceInMinutes = 1440

                const val = localStorage.getItem("dc_latestNotificationDate")
                if (val !== null && val !== undefined) {
                    const latestNotificationDate = new Date(val)
                    difference = currentDate - latestNotificationDate
                    differenceInMinutes = difference / (1000 * 60)
                }

                if (differenceInMinutes > 30) {
                    displayWhatsAppConnNotification("Please check your WhatsApp connection")
                    localStorage.setItem("dc_latestNotificationDate", currentDate.toISOString())
                }
            }
        } catch (error) {
            console.error('Error fetching latest message data', error)
        }
    }

    useEffect(() => {
        if (!state.loggedIn) return
        fetchLatestMessageData()
        const intervalId = setInterval(fetchLatestMessageData, POLLING_INTERVAL_MSG)

        return () => clearInterval(intervalId) // Clean up on unmount
    }, [])

    const POLLING_INTERVAL_MSG_ALL = 60000  // 1min

    const fetchLatestMessageDates = async () => {
        if (!state.loggedIn || state.user.role === "GUEST" || state.user.role === "OTHER") return
        try {
            const response = await api.get('check_latest_message_times/', state.token.get_config)
            const nameTimeDict = response.data["latest_message_dates"]
            const updatedNameTimeDict = nameTimeDict.map(item => ({
                ...item,
                time: new Date(item.time)
            }))
            dispatch({
                type: "latestMessageDates",
                data: updatedNameTimeDict
            })
        } catch (error) {
            console.error('Error fetching latest message dates', error)
        }
    }

    useEffect(() => {
        if (!state.loggedIn) return
        fetchLatestMessageDates()
        const intervalId = setInterval(fetchLatestMessageDates, POLLING_INTERVAL_MSG_ALL)

        return () => clearInterval(intervalId) // Clean up on unmount
    }, [])

    useEffect(() => {
        if (!state.loggedIn) return

        const ourRequest = Axios.CancelToken.source()

        async function user() {
            try {
                const response = await api.get('users/me/', state.token.get_config, { cancelToken: ourRequest.token })
                //console.log(response.data)
                dispatch({
                    type: "user",
                    data: {
                        username: response.data.full_name,
                        email: response.data.email,
                        phonenumber: response.data.phone_number,
                        isstaff: response.data.is_staff,
                        role: response.data.role,
                        kvkk_sign_date: response.data.kvkk_sign_date,
                        node: response.data.node,
                    }
                })
            } catch (err) {
                //console.log(err)
                dispatch({
                    type: "error",
                    err: err,
                    data: "Unable to access user information!"
                })
                dispatch({type: "logout", })
            }
        }
        user()
        return () => {
            ourRequest.cancel()
        }
    }, [state.loggedIn])


    const [napi, contextHolder] = notification.useNotification()

    function openNotification(notificationType, msg) {
        napi[notificationType]({
            //key: 'updatable',
            message: msg,
            duration: 3.5,
            placement: "topRight",
        })
    }

    useEffect(() => {
        if (state.successCounter === 0) return
        openNotification('success', state.message)
    }, [state.successCounter])

    useEffect(() => {
        if (state.infoCounter === 0) return
        openNotification('info', state.message)
    }, [state.infoCounter])

    useEffect(() => {
        if (state.errorCounter === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function log() {
            let error_message = { 'message':state.message }
            if (state.err && state.err.response && state.err.response.data) {
                const errorMessages = []
                // Iterate over error response properties and collect messages
                for (const field in state.err.response.data) {
                    if (state.err.response.data.hasOwnProperty(field)) {
                        errorMessages.push(state.err.response.data[field])
                    }
                }
                // Join error messages to form a comprehensive message
                const mergedErrorMessage = errorMessages.join(' ')
                error_message = { 'message':mergedErrorMessage }
            }
            openNotification('error', state.message)
            try {
                const response = await api.post('log_client_errors/',
                    error_message, state.token.post_config, { cancelToken: ourRequest.token })
                //openNotification('error', response.data.message)
            } catch (err) {
                //console.log(err)
            }
        }
        log()
        return () => {
            ourRequest.cancel()
        }

    }, [state.errorCounter])

    const [kvkkData, setKvkkData] = useImmer({modal: {isOpen: false}})

    const [refreshClicked, setRefreshClicked] = useImmer({isClicked: false})

    let nodeDict = {}
    for (let node of NODES) {
        nodeDict[node] = ""
    }
    const [socketState, setSocketState] = useImmer({
        data: nodeDict,
    })

    const [waState, setWaState] = useImmer({
        header: "",
        message: "",
        qrCode: "",
        connected: false,
    })

    function onRefreshClick() {
        //console.log(socket)
        if (socket === null) return
        setRefreshClicked(draft => {
            draft.isClicked = true
        })
        setTimeout(() => {
            setRefreshClicked(draft => {
                draft.isClicked = false
            })
        }, 20000) // 20 seconds
        socket.emit("destroy-client", {id: state.user.phonenumber, /*token: state.token.value*/})
    }

    useEffect(() => {
        if (!state.loggedIn) return
        if (state.user === null || state.user.phonenumber === null || state.user.username === "admin") return
        if (state.requestCounter === 0) return

        if (state.user.role === "OTHER") return
        if (!state.user.isstaff) return

        if (!state.user.kvkk_sign_date && state.user.username !== "admin") {
            setKvkkData(draft => {
                draft.modal.isOpen = true
            })
            return
        }

        //console.log(state.user)

        /* Socket */
        if (socket === null) {
            if (SOCKET_URL.includes("localhost")) {
                socket = io(SOCKET_URL)
            }  else {
                socket = io(SOCKET_URL, {path: "/" + state.user.node + SOCKET_PATH, })
            }
        }
    
        socket.on('connect', () => {
            //console.log(socket.id)
            setSocketState(draft => {
                draft.data[state.user.node] = "connected"
            })
            if (state.user.phonenumber !== null) {
                socket.emit("client-data", {id: state.user.phonenumber, /*token: state.token.value*/})
            }
        })
    
        socket.on('connect_error', () => {
            //console.log('connect_error')
            //displayNotification('Socket Connection Error!')
            setSocketState(draft => {
                draft.data[state.user.node] = "connection error"
            })
            setWaState(draft => {
                draft.connected = false
            })
        })
    
        socket.on('disconnect', () => {
            //displayNotification('Socket Disconnected!')
            setSocketState(draft => {
                draft.data[state.user.node] = "disconnected"
            })
            setWaState(draft => {
                draft.connected = false
            })
        })

        /*socket.on('restart', () => {
            socket.emit("client-data", {id: state.user.phonenumber, token: state.token.value})
        })*/

        /* WhatsApp */
        socket.on('qr', (data) => {
            //if (waState.connected) return
            setWaState(draft => {
                draft.qrCode = data.qrCode
                draft.header = data.message
                draft.message = ""
                //draft.connected = false
            })
        })

        socket.on('loading', (data) => {
            setWaState(draft => {
                draft.qrCode = ""
                draft.header = data
                draft.message = ""
                //draft.connected = false
            })
        })

        socket.on('ready', (data) => {
            setWaState(draft => {
                draft.qrCode = ""
                draft.header = data
                draft.message = ""
                draft.connected = true
            })
        })

        socket.on('authenticated', (data) => {
            setWaState(draft => {
                draft.qrCode = ""
                draft.header = data
                draft.message = ""
                //draft.connected = true
            })
        })

        socket.on('auth_failure', (data) => {
            //displayNotification('Authentication failed!')
            setWaState(draft => {
                draft.qrCode = ""
                draft.header = data
                draft.message = ""
                draft.connected = false
            })
        })

        socket.on('disconnected', (data) => {
            //displayNotification('WhatsApp disconnected!')
            setWaState(draft => {
                draft.qrCode = ""
                draft.header = data
                draft.message = ""
                draft.connected = false
            })
        })

        socket.on('message', (data) => {
            setWaState(draft => {
                draft.qrCode = ""
                draft.header = [getLocalTime(data.time, true), data.from, data.name].join(", ")
                draft.message = data.body
                draft.connected = true
            })
        })

        socket.on('conn_state', (data) => {
            if (data.state) {
                if (data.message !== undefined) {
                    setWaState(draft => {
                        draft.qrCode = ""
                        draft.header = [getLocalTime(data.message.time, true), data.message.from, data.message.name].join(", ")
                        draft.message = data.message.body
                        draft.connected = data.state
                    })
                } else {
                    setWaState(draft => {
                        draft.qrCode = ""
                        draft.header = "WhatsApp is connected!"
                        draft.message = ""
                        draft.connected = data.state
                    })
                }
            } else {
                setWaState(draft => {
                    draft.qrCode = ""
                    draft.header = "WhatsApp is not connected! Please wait to receive QR code.."
                    draft.message = ""
                    draft.connected = data.state
                })
            }
        })

        return () => {
            socket.off('connect')
            socket.off('connect_error')
            socket.off('disconnect')
            socket.off('qr')
            socket.off('loading')
            socket.off('ready')
            socket.off('authenticated')
            socket.off('auth_failure')
            socket.off('disconnected')
            socket.off('message')
            socket.off('conn_state')
        }
    }, [state.requestCounter, ])

    
    return (
        <ErrorBoundary dispatchContext={dispatch}>
            {contextHolder}
            <StateContext.Provider value={state}>
                <DispatchContext.Provider value={dispatch}>
                    {!state.user.kvkk_sign_date && state.loggedIn && state.user.username !== "admin" && state.user.role !== "GUEST" ? 
                        <KvkkModal
                            state={kvkkData}
                            setState={setKvkkData}/>
                        :
                        <BrowserRouter>
                            <Header />
                            <Routes>
                                <Route path="/" element={<MessagesTable connected={waState.connected} />} />
                                <Route path="/purchasing" element={state.user.role === "SALESMAN" || state.user.role === "GUEST" ? <NotFound /> : <PurchasingScreen connected={waState.connected} />} />
                                <Route path="/deals" element={state.user.role?.startsWith("SALES") || state.user.role === "BUYER" || state.user.role === "GUEST" ? <NotFound /> : <DealsTable connected={waState.connected} />} />
                                <Route path="/message/:messageId" element={state.user.role?.startsWith("SALES") || state.user.role === "BUYER" || state.user.role === "GUEST" ? <NotFound /> : <DealsTable connected={waState.connected} />} />
                                <Route path="/mapping/:mappingId" element={state.user.role?.startsWith("SALES") || state.user.role === "BUYER" || state.user.role === "GUEST" ? <NotFound /> : <DealsTable connected={waState.connected} />} />
                                <Route path="/messages" element={<MessagesTable connected={waState.connected} />} />
                                <Route path="/messages/message/:messageId" element={/*state.user.role === "SALESMAN" ? <NotFound /> : */<MessagesTable connected={waState.connected} />} />
                                <Route path="/messages/mapping/:mappingId" element={state.user.role?.startsWith("SALES") || state.user.role === "BUYER" || state.user.role === "GUEST" ? <NotFound /> : <MessagesTable connected={waState.connected} />} />
                                <Route path="/search/:searchState" element={<MessagesTable connected={waState.connected} />} />
                                <Route path="/contact/:contactId" element={state.user.role === "GUEST" ? <NotFound /> : <ContactScreen connected={waState.connected} />} />
                                {/*<Route path="/manage_mappings" element={state.user.role === "DATA MANAGER" ? <ManageMappingsTable connected={waState.connected} /> : <NotFound />} />*/}
                                {/*<Route path="/mappings" element={state.user.role === "DATA MANAGER" ? <MappingsTable connected={waState.connected} /> : <NotFound />} />*/}
                                <Route path="/reaches" element={state.user.role === "GUEST" ? <NotFound /> : <ReachScreen connected={waState.connected} />} />
                                <Route path="/reaches/:messageIds" element={state.user.role === "GUEST" ? <NotFound /> : <ReachScreen connected={waState.connected} />} />
                                {/*<Route path="/file_upload" element={state.user.role === "DATA MANAGER" ? <FileUploadScreen connected={waState.connected} /> : <NotFound />} />*/}
                                <Route path="/chatlinks" element={state.user.role === "GUEST" ? <NotFound /> : <ChatLinksTable connected={waState.connected} />} />
                                {/*<Route path="/contacts" element={<ContactsTable connected={waState.connected} />} />*/}
                                {/*state.user !== null && (state.user.username === "admin" || state.user.role === "SALES MANAGER") ? */}
                                <Route path="/whatsapp2" element={state.user.role === "GUEST" || state.user.role === "SALESMAN" || state.user.role === "BUYER" ? <NotFound /> : <WhatsAppAdmin socketState={socketState} setSocketState={setSocketState} />} />
                                <Route path="/whatsapp" element={state.user.role === "GUEST" || state.user.username === "admin" ? <NotFound /> : <WhatsApp waState={waState} socketState={socketState} onRefreshClick={onRefreshClick} refreshClicked={refreshClicked} />} />
                                <Route path="/settings" element={<Settings connected={waState.connected} />} />
                                {/*<Route path="/test" element={state.user.role === "DATA MANAGER" ? <Test /> : <NotFound />} />*/}
                                {/*<Route path="/forgot" element={state.user.role === "GUEST" ? <NotFound /> : <Forgot />} />*/}
                                {/*<Route path="/reset" element={state.user.role === "GUEST" ? <NotFound /> : <ResetPassword />} />*/}
                                <Route path="/jump" element={<Login />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </BrowserRouter>
                    }
                </DispatchContext.Provider>
            </StateContext.Provider>
        </ErrorBoundary>
    )
}

export default App