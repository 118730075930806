import { useEffect, useContext, useRef } from "react"
import { useImmer } from "use-immer"
import { List, Button, Space, Tooltip, Spin, Avatar, Input } from 'antd'
import { LoadingOutlined, FileImageOutlined, FileExcelOutlined, DownloadOutlined, PicCenterOutlined } from '@ant-design/icons'
import { base64ToBlob, createDataFromFileContent, getLocalTime, handleDownload, isExcelFile, parseExcelFilePromise } from "../Utils"
import MessageContentModal from "./MessageContentModal"
import SideMenu from './SideMenu'
import Login from "./Login"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

const { Search } = Input


function PurchasingScreen(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const chatWindowRef = useRef(null)

    const [messageState, setMessageState] = useImmer(
        {isLoading:false, data:null, page:1, pageSize:100, searchText:"", requestCount:0, prevContainerHeight:0, isScroll:false})

    const [messageContentData, setMessageContentData] = useImmer(
        {title:"", content:"", previewFile:null, previewFileCnt:0, msgId:-1, isModal:false, modal: {isOpen: false}})

    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function listData() {
            setMessageState(draft => {draft.isLoading = true})
            try {
                let url = 'get_purchasing_group_info/'
                url += `?page_size=${messageState.pageSize}`
                url += `&page=${messageState.page}`

                if (messageState.searchText !== "") {
                    url += `&search_text=${messageState.searchText}`
                }

                const response = await api.get(url, appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(response.data)

                response.data['messages'] = await Promise.all(
                    response.data['messages'].map(async (message) => ({
                        ...message,
                        files_data: await Promise.all(
                            message.files_data.map(async (file) => {
                                const fileBlob = base64ToBlob(file.content, file.type)
                                const newFileData = createDataFromFileContent(file)
                                if (isExcelFile(file.type)) {
                                    const excelData = await parseExcelFilePromise(fileBlob)
                                    return { ...file, ...newFileData, excelData }
                                } else {
                                    return { ...file, ...newFileData }
                                }
                            })
                        ),
                    }))
                )
                //console.log(response.data)

                if (messageState.data === null || messageState.page === 1) {
                    setMessageState(draft => {
                        draft.data = response.data
                        draft.isScroll = true
                    })
                } else {
                    const cumMessages = response.data.messages.concat(messageState.data.messages)
                    setMessageState(draft => {
                        draft.data.messages = cumMessages
                    })
                }
            } catch (err) {
                console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Error while listing data!"
                })
            }
            setMessageState(draft => {draft.isLoading = false})
        }
        listData()

        return () => {
            ourRequest.cancel()
        }
    }, [messageState.requestCount])

    useEffect(() => {
        if (!messageState.isScroll) return
        if (chatWindowRef === null || chatWindowRef.current === null) return
        if (messageState.requestCount === 0 || messageState.page === 1) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight
        } else {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight - messageState.prevContainerHeight
        }
        setMessageState(draft => {
            draft.prevContainerHeight = chatWindowRef.current.scrollHeight
        })
    }, [messageState.data])

    const handleScroll = (e) => {
        /*if (e.target.scrollTop === 0) {} */
        setMessageState(draft => {
            draft.isScroll = true
            draft.page++
            draft.requestCount++
        })
    }

    const handleRefresh = (e) => {
        setMessageState(draft => {
            draft.isScroll = true
            draft.page = 1
            draft.requestCount++
        })
    }

    const onSearch = (value) => {
        setMessageState(draft => {
            draft.searchText = value
            draft.page = 1
            draft.requestCount++
        })
    }

    function displayImageAndContent(message) {
        let imageComp = ""

        if (message.files_data.length > 0) {
            const file = message.files_data[0]
            if (file.isImage) {
                imageComp = (
                    <TransformWrapper
                        defaultScale={1}
                        wheel={{ step: 0.5 }}
                        pan={{
                            disablePadding: true, // Allows panning beyond container bounds
                        }}
                        limitToBounds={false}
                        minScale={0.25}
                        maxScale={4}
                    >
                        {({ resetTransform }) => (
                            <>
                                <Tooltip title="Reset Image Position">
                                    <Button type="primary" onClick={() => {resetTransform()}} style={{
                                        position: "relative", top: 40, left: 10, zIndex: 10,
                                    }} icon={<PicCenterOutlined />} />
                                </Tooltip>
                                <TransformComponent>
                                    <img
                                        src={file.content}
                                        alt={file.name}
                                        style={{
                                            minWidth:"200px", minHeight:"200px", maxWidth: "30%", maxHeight: "30%",
                                            objectFit: "contain", pointerEvents: "none", border: "1px dashed #ccc",
                                        }}
                                    />
                                </TransformComponent>
                            </>
                        )}
                    </TransformWrapper>
                )
            }
        } 
        return (
            <>
                {imageComp}
                <Space direction="vertical">
                    {message.quoted_content !== "" ? 
                        <Space direction="vertical" size="small">
                            <strong style={{fontSize:"24px", fontWeight:"bold"}}>{"“"}</strong>
                            <div style={{fontStyle:"italic", paddingLeft:"10px", marginTop:"-15px"}}>
                                {message.quoted_content}
                            </div>
                            <strong style={{fontSize:"24px", fontWeight:"bold"}}>{"”"}</strong>
                        </Space>
                    : ""}
                    {message.content}
                </Space>
            </>
        )
    }

    if (!appState.loggedIn) return <Login />

    return (
        <div className='App'>
            <SideMenu connected={props.connected}/>
            <div style={{width:'12px'}}/>
            {messageState.isLoading ?
                <div style={{minWidth:"823px", height:"800px"}}>
                    <Spin style={{top:"50%", left:"35%", }} indicator={
                        <LoadingOutlined
                            style={{
                                fontSize: 192,
                            }}
                            spin
                        />
                    }/>
                </div>
            :
                <Space direction="vertical">
                    <Search style={{width:"400px", marginTop:"10px", display:'block', marginLeft:'auto',}} 
                        value={messageState.searchText} onChange={(e) => setMessageState(draft => {draft.searchText = e.target.value})} onSearch={onSearch} placeholder="Search in messages (Last 30 days)" enterButton allowClear />
                    <div className="chat-window" style={{width:"800px", height:"900px"}} ref={chatWindowRef} /*onScroll={handleScroll}*/>
                        {(messageState.data !== null && (messageState.page * messageState.pageSize < messageState.data.count)) ? 
                            <Button style={{display:'block', marginLeft:'auto',}} type="link" onClick={handleScroll}>Load Older Messages</Button> : ""}
                        <List
                            style={{cursor:"pointer"}}
                            className="message-list"
                            itemLayout="horizontal"
                            dataSource={messageState.data === null ? [] : messageState.data.messages}
                            renderItem={(message, index) => (
                                <List.Item key={index} style={{
                                        backgroundColor: (index % 2 === 0) ? '#f0f0f0' : '#fafafa',
                                        borderRadius:4, 
                                    }}>
                                    <List.Item.Meta
                                        avatar={<Space direction="vertical" size="small">
                                                    <small style={{paddingLeft:6}}>
                                                        <strong>
                                                            {`${messageState.data.count - (messageState.data.messages.length - index - 1)}/${messageState.data.count}`}
                                                        </strong>
                                                    </small>
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: message.is_incoming ? '#f56a00' : '#87d068',
                                                            marginTop:-6, marginLeft:6
                                                        }}
                                                        //size="large"
                                                    >
                                                        {message.sender_name[0]}
                                                    </Avatar>
                                                </Space>}
                                        title={
                                            <Space>
                                                <Space direction="vertical" size="small">
                                                    <div>{message.sender_name}</div>
                                                    <div className="chat-time" style={{paddingRight:6}}>
                                                        <strong>{getLocalTime(message.message_time)}</strong>
                                                    </div>
                                                </Space>
                                                {message.files_data.map((file, index) => {
                                                    return (
                                                        <div>
                                                            <Tooltip
                                                                key={index}
                                                                placement="bottom"
                                                                title={file.name}
                                                            >
                                                                {file.isImage ? 
                                                                    <FileImageOutlined
                                                                        style={{fontSize: '21px', color:'#1890ff', cursor:'pointer'}}
                                                                        onClick={() => { setMessageContentData(draft => {
                                                                            draft.previewFile = file
                                                                            draft.previewFileCnt++
                                                                        }) }}
                                                                    /> :
                                                                    <FileExcelOutlined
                                                                        style={{fontSize: '21px', color:'#52c41a', cursor:'pointer'}}
                                                                        onClick={() => { setMessageContentData(draft => {
                                                                            draft.previewFile = file
                                                                            draft.previewFileCnt++
                                                                        }) }}
                                                                    />
                                                                }
                                                            </Tooltip>
                                                            {"  "}
                                                            <Tooltip title="Download file" placement='bottom'>
                                                                <DownloadOutlined style={{color:"#7BB6E2", paddingBottom:"30px", fontSize:"18px"}} onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    handleDownload(file)
                                                                }}/>
                                                            </Tooltip>
                                                        </div>
                                                    )
                                                })}
                                            </Space>
                                        }
                                        description={
                                            <div className="withprewrap" style={{maxHeight:"300px", overflowY:"auto"}}>
                                                {displayImageAndContent(message)}
                                            </div>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                    <Button style={{display:'block', marginLeft:'auto',}} type="link" onClick={handleRefresh}>Load New Messages</Button>
                </Space>
            }
            <MessageContentModal
                state={messageContentData}
                setState={setMessageContentData}
                notDisplayOkButton={true}
                displayCopyButton={true}
            />
        </div>
    )
}

export default PurchasingScreen
