import { PublicClientApplication } from "@azure/msal-browser"

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: process.env.REACT_APP_MSAL_AUTHORITY,
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
}

const msalInstance = new PublicClientApplication(msalConfig)

export default msalInstance
