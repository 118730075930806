import React from "react"
import { useEffect, useContext } from 'react'
import { useImmer } from "use-immer"
import { useMsal } from "@azure/msal-react"
import { Button } from 'antd'
import { WindowsOutlined } from '@ant-design/icons'

import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'


const MSLoginButton = (props) => {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [loginData, setLoginData] = useImmer({accessToken: null, requestCount: 0, isLoading:false})

    const { instance } = useMsal()

    const handleLogin = () => {
        /*setLoginData(draft => {
            draft.accessToken = null
            draft.requestCount++
        })*/

        instance.loginPopup().then((response) => {
            //console.log("Access Token:", response.accessToken)
            
            setLoginData(draft => {
                draft.accessToken = response.accessToken
                draft.requestCount++
            })

        }).catch((error) => {
            //alert(JSON.stringify(error, null, 2))
            console.error(error)
        })
    }

    useEffect(() => {
        if (loginData.requestCount === 0 || loginData.accessToken === null) return

        const ourRequest = Axios.CancelToken.source()

        async function validateToken() {
            setLoginData(draft => {draft.isLoading = true })
            try {
                const response = await api.post(
                    'microsoft_login/',
                    { 'access_token': loginData.accessToken },
                    { cancelToken: ourRequest.token }
                )
                //console.log(response.data)

                const data = response.data
                if (data.success) {
                    //console.log("Authenticated")
                    appDispatch({
                        type: "login",
                        data: data.token,
                    })
                } else {
                    //console.error("Authentication failed:", data.error)
                    appDispatch({
                        type: "error",
                        data: "Unable to login with Microsoft login"
                    })
                }
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to login with Microsoft login"
                })
            }
            setLoginData(draft => {draft.isLoading = false })
        }
        validateToken()

        return () => {
            ourRequest.cancel()
        }
    }, [loginData.requestCount])

    return (
        <Button
            disabled={loginData.isLoading}
            type="primary"
            icon={<WindowsOutlined />}
            onClick={handleLogin}
            size="large"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '250px',
                height: '50px',
                fontSize: '16px',
                borderRadius: '5px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            }}
        >
            Login with Microsoft
        </Button>
    )
}

export default MSLoginButton