import { useEffect, useContext } from 'react'
import { useParams, NavLink } from "react-router-dom"
import { useImmer } from "use-immer"
import { Space, Table, Spin, Tooltip, Checkbox, message, Popconfirm, Tag, Button } from "antd"
import { WhatsAppOutlined, TableOutlined, ExportOutlined, PicLeftOutlined } from '@ant-design/icons'
import handelot_logo from '../handelot.png'
import SideMenu from './SideMenu'
import Login from './Login'
import MessagesSearchBar from './MessagesSearchBar'
import GenericWarningModal from './GenericWarningModal'
import ExtractUPCsAndGetProductInfoModal from './ExtractUPCsAndGetProductInfoModal'
import TableFilters from './TableFilters'
import MessageActions from './MessageActions'
import ContactActions from './ContactActions'
import ContactTags from './ContactTags'
import AntTagWithTooltip from './AntTagWithTooltip'
import { useFetchProductModels } from "./ProductModels"
import { useFetchBrands, useFetchCategories, useFetchContactTags, useFetchCountries, useFetchRegions, useFetchDealers } from './DataFetcher'
import { useFetchMessageStates } from './MessageStates'
import { copyToClipboard, cropText, getContactInfoAppendedMessageContent, getLocalTime, getOneMinuteBeforeUTC, isDateNew, returnDivFromText, } from '../Utils'
import { DEAL_TYPES, SOURCE_TYPES, LAST_3_MONTHS, NORMAL_SEARCH } from '../Constants'

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'
import MessageContentModal from './MessageContentModal'


function MessagesTable(props) {
    const MAX_MSG_LEN = 200

    const { messageId, mappingId, searchState } = useParams()

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [messageApi, messageContextHolder] = message.useMessage()

    const [dataSource, setDataSource] = useImmer({
        messages: [],
        vendor_count: 0,
        pagination: {current:1, pageSize:20, total:0, showSizeChanger: true, showQuickJumper: true,
            pageSizeOptions: ['20', '40', '60', '80', '100'], /*position: ["topRight", ],*/ },
        isThereFilterChanges:false,
        queryDateTime: getOneMinuteBeforeUTC(), //new Date().toUTCString(),
        isLoading: true,
        currentTable: "Messages",
    })
    const [productModelState, setProductModelState] = useImmer({payload: [], isLoading: true})
    const [messageStatesState, setMessageStatesState] = useImmer({payload: [], isLoading: true})
    const [genericWarningData, setGenericWarningData] = useImmer({title:"", showNLPResult: false, bmList:[], modal: {isOpen: false}})
    const [messageContentData, setMessageContentData] = useImmer({title:"", content:"", msgId:-1, isModal:true, modal: {isOpen: false}})
    const [fromUPCToProductData, setFromUPCToProductData] = useImmer({content:"", msgId:-1, modal: {isOpen: false}})
    const [spammerData, setSpammerData] = useImmer({spammerId:null, isSpammer:false, requestCount:0})

    const [filterOptions, setFilterOptions] = useImmer({
        requestCount:0,
        startDate: new Date(new Date().getTime() - LAST_3_MONTHS).toUTCString(), //null,
        endDate: null,
        messageID: '',
        isListConcealedMsgs: false,
        contactNumbers:{selected:[], excluded:[], },
        messageKeywords:{selected:[], },
        productPNKeywords:{selected:[], keywordMap:{}, isLoading:false, isLoading2:false, searchType:"product"},
        isExactMatch: false,
        isListFavoritesOnly: false,
        contactTags:{data:[], selected:[], excluded:[], requestCount:0, isLoading:false},
        isListFavoriteContactsOnly: false,
        waGroupNames:{data:[], selected:[], requestCount:0, isLoading:false},
        productModelNames:{data:[], selected:[], requestCount:0, isLoading:false},
        productPartNumbers:{data:[], selected:[], requestCount:0, isLoading:false},
        productSpecs:{data:[], selected:[], requestCount:0, isLoading:false},
        smallWAGroups: false,
        largeWAGroups: false,
        dealTypes:{data:DEAL_TYPES, selected:[]},
        sourceTypes:{data:appState.user.role === "SALESMAN" ? SOURCE_TYPES.slice(0, -1) : SOURCE_TYPES, selected:[]},
        isListSpammerItems: false,
        productModels:{data:[], selected:[], requestCount:0, isLoading:false},
        brands:{data:[], selected:[], requestCount:0, isLoading:false},
        categories:{data:[], selected:[], requestCount:0, isLoading:false},
        specs:{selected:[], },
        vendors:{data:[], selected:[], requestCount:0, isLoading:false},
        countries:{data:[], selected:[], excluded:[], requestCount:0, isLoading:false},
        regions:{data:[], selected:[], excluded:[], requestCount:0, isLoading:false},
        dealers:{data:[], selected:[], requestCount:0, isLoading:false},
        states:{data:[], selected:[], requestCount:0, isLoading:false},
        lastReachHours: 0,
        db: 1,
        searchMethod: NORMAL_SEARCH,
        parsedSearchState: undefined,
    })

    const [sortingOptions, setSortingOptions] = useImmer({
        columnName: undefined,
        sortType: undefined,
        requestCount: 0,
    })

    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchMessages() {
            if (!appState.loggedIn) return

            if (searchState !== undefined && filterOptions.parsedSearchState === undefined) {
                const parsedSearchState = JSON.parse(decodeURIComponent(searchState))
                console.log(parsedSearchState)
                setFilterOptions(draft => {
                    draft.searchMethod = parsedSearchState.searchMethod
                    draft.isExactMatch = parsedSearchState.isExactMatch
                    draft.messageKeywords.selected = parsedSearchState.messageKeywords
                    draft.productModels.selected = parsedSearchState.productModels
                    draft.waGroupNames.selected = parsedSearchState.waGroupNames
                    draft.parsedSearchState = parsedSearchState
                    draft.requestCount++
                })
                return
            }

            if (dataSource.isThereFilterChanges) {
                setGenericWarningData(draft => {
                    draft.title = 'There are changes in filtering. Please apply them first!'
                    draft.showNLPResult = false
                    draft.modal.isOpen = true
                })
                return
            }

            setDataSource(draft => {
                draft.isLoading = true
            })

            try {
                let post_data = {
                    "page_size": dataSource.pagination.pageSize,
                    "page": dataSource.pagination.current,
                }

                if (messageId === undefined && mappingId === undefined) {
                    post_data["query_time"] = dataSource.queryDateTime
                    post_data["db"] = filterOptions.db
                    post_data["search_method"] = filterOptions.searchMethod
                    if (dataSource.currentTable === "Contacts") {
                        post_data["list_contacts"] = true
                    }
                    if (filterOptions.startDate !== null) {
                        post_data["start_date"] = filterOptions.startDate
                    }
                    if (filterOptions.endDate !== null) {
                        post_data["end_date"] = filterOptions.endDate
                    }
                    if (filterOptions.isListConcealedMsgs) {
                        post_data["list_concealed_msgs"] = true
                    }
                    if (filterOptions.messageID !== "") {
                        post_data["message_id"] = filterOptions.messageID
                    }
                    if (filterOptions.lastReachHours > 0) {
                        post_data["last_reach_hours"] = filterOptions.lastReachHours
                    }
                    /*if (filterOptions.contactNumber !== "") {
                        post_data["contact_number"] = filterOptions.contactNumber.replace(/\D/g, '')
                    }*/
                    if (filterOptions.contactNumbers.selected.length > 0) {
                        post_data["contact_numbers"] = filterOptions.contactNumbers.selected.map(val => val.replace(/\D/g, ''))
                    }
                    if (filterOptions.contactNumbers.excluded.length > 0) {
                        post_data["excluded_contact_numbers"] = filterOptions.contactNumbers.excluded.map(val => val.replace(/\D/g, ''))
                    }
                    if (filterOptions.isExactMatch) {
                        post_data["exact_match"] = true
                    }
                    if (filterOptions.isListFavoritesOnly) {
                        post_data["favorites_only"] = true
                    }
                    if (filterOptions.isListFavoriteContactsOnly) {
                        post_data["favorite_contacts_only"] = true
                    }
                    post_data["message_keywords"] = filterOptions.messageKeywords.selected.map(val => val.replace(/&/g, "[AND_SYMBOL]"))
                    if (filterOptions.productPNKeywords.selected.length > 0) {
                        let part_number_keywords = []
                        let product_keywords = []
                        let product_keywords_exclude = []
                        let product_keywords_other = []
                        Object.values(filterOptions.productPNKeywords.keywordMap).forEach((item) => {
                            if ("part_number" in item) {
                                part_number_keywords.push(encodeURIComponent(item["part_number"]))
                            }
                            if ("include" in item) {
                                item["include"].forEach((_item) => {
                                    product_keywords.push(encodeURIComponent(_item))
                                })
                            }
                            if ("exclude" in item) {
                                item["exclude"].forEach((_item) => {
                                    product_keywords_exclude.push(encodeURIComponent(_item))
                                })
                            }
                            if ("other_include" in item) {
                                item["other_include"].forEach((_item) => {
                                    product_keywords_other.push(encodeURIComponent(_item))
                                })
                            }
                        })
                        if (part_number_keywords.length > 0) {
                            post_data["part_number_keywords"] = part_number_keywords
                        }
                        if (product_keywords.length > 0) {
                            post_data["product_keywords"] = product_keywords
                        }
                        if (product_keywords_exclude.length > 0) {
                            post_data["product_keywords_exclude"] = product_keywords_exclude
                        }
                        if (product_keywords_other.length > 0) {
                            post_data["product_keywords_other"] = product_keywords_other
                        }
                    }
                    if (filterOptions.waGroupNames.selected.length > 0) {
                        post_data["wa_group_names"] = filterOptions.waGroupNames.selected.map(name => encodeURIComponent(name))
                    }
                    if (filterOptions.productModelNames.selected.length > 0) {
                        post_data["product_model_names"] = filterOptions.productModelNames.selected.map(name => encodeURIComponent(name))
                    }
                    if (filterOptions.productPartNumbers.selected.length > 0) {
                        post_data["product_part_numbers"] = filterOptions.productPartNumbers.selected.map(name => encodeURIComponent(name))
                    }
                    if (filterOptions.productSpecs.selected.length > 0) {
                        post_data["product_specs"] = filterOptions.productSpecs.selected.map(name => encodeURIComponent(name))
                    }
                    if (filterOptions.smallWAGroups) {
                        post_data["small_wa_groups"] = true
                    }
                    if (filterOptions.largeWAGroups) {
                        post_data["large_wa_groups"] = true
                    }
                    if (filterOptions.dealTypes.selected.length > 0) {
                        post_data["deal_types"] = filterOptions.dealTypes.selected
                    }
                    if (filterOptions.sourceTypes.selected.length > 0) {
                        post_data["source_types"] = filterOptions.sourceTypes.selected
                    }
                    if (filterOptions.productModels.selected.length > 0) {
                        post_data["model_ids"] = filterOptions.productModels.selected
                    }
                    if (filterOptions.brands.selected.length > 0) {
                        post_data["brand_ids"] = filterOptions.brands.selected
                    }
                    if (filterOptions.categories.selected.length > 0) {
                        post_data["category_ids"] = filterOptions.categories.selected
                    }
                    if (filterOptions.specs.selected.length > 0) {
                        post_data["specs"] = filterOptions.specs.selected
                    }
                    if (filterOptions.isListSpammerItems) {
                        post_data["list_spammer_items"] = true
                    }
                    if (filterOptions.vendors.selected.length > 0) {
                        post_data["vendor_ids"] =  filterOptions.vendors.selected.filter(
                            vendor => vendor.key !== undefined
                        ).map(vendor => vendor.key)
                    }
                    if (filterOptions.countries.selected.length > 0) {
                        post_data["country_ids"] = filterOptions.countries.selected
                    }
                    if (filterOptions.countries.excluded.length > 0) {
                        post_data["excluded_country_ids"] = filterOptions.countries.excluded
                    }
                    if (filterOptions.regions.selected.length > 0) {
                        post_data["region_ids"] = filterOptions.regions.selected
                    }
                    if (filterOptions.regions.excluded.length > 0) {
                        post_data["excluded_region_ids"] = filterOptions.regions.excluded
                    }
                    if (filterOptions.dealers.selected.length > 0) {
                        post_data["dealer_ids"] = filterOptions.dealers.selected
                    }
                    if (filterOptions.contactTags.selected.length > 0) {
                        post_data["contact_tags"] = filterOptions.contactTags.selected
                    }
                    if (filterOptions.contactTags.excluded.length > 0) {
                        post_data["excluded_contact_tags"] = filterOptions.contactTags.excluded
                    }
                    if (filterOptions.states.selected.length > 0) {
                        post_data["states"] = filterOptions.states.selected
                    }
                } else if (messageId !== undefined) {
                    post_data["message_id"] = messageId
                } else if (mappingId !== undefined) {
                    post_data["mapping_id"] = mappingId
                }

                if (sortingOptions.columnName !== undefined) {
                    post_data["sort_column"] = sortingOptions.columnName
                    post_data["sort_type"] = sortingOptions.sortType
                }

                const startTime = performance.now()
                const response = await api.post("list_messages/", post_data, appState.token.post_config, { cancelToken: ourRequest.token })
                const endTime = performance.now()
                const elapsedTime = endTime - startTime // Time in milliseconds
                console.log(`Elapsed time: ${elapsedTime} ms`)

                //console.log(response.data)

                const dataSourceWithKey = response.data["results"].map((row) => {
                    //console.log(row)
                    return {
                        ...row,
                        'key': row['message']['id'], /* rowKey="id" not working */
                        'showFullMsg': false,
                        'showAllGroups': false,
                        'showAllContactTags': false,
                        'isNavLivkClicked': false,
                    }
                })

                //console.log(dataSourceWithKey)

                setDataSource(draft => {
                    draft.messages = dataSourceWithKey
                    draft.pagination.total = response.data["count"]
                    draft.vendor_count = response.data["vendor_count"]
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch messages!"
                })
            }

            setDataSource(draft => {
                draft.isLoading = false
            })
        }
        fetchMessages()
        return () => {
            ourRequest.cancel()
        }
    }, [dataSource.pagination.current,
        dataSource.pagination.pageSize,
        dataSource.currentTable,
        filterOptions.requestCount,
        sortingOptions.requestCount,
        appState.loggedIn,
        messageId,
    ])

    useEffect(() => {
        //console.log(filterOptions.productPNKeywords.selected)
        //console.log(filterOptions.productPNKeywords.keywordMap)

        setFilterOptions(draft => {
            draft.productPNKeywords.isLoading2 = true
        })

        let newKeywordMap = {...filterOptions.productPNKeywords.keywordMap}

        const ourRequest = Axios.CancelToken.source()

        async function searchBrandModels(_kw) {

            setFilterOptions(draft => {
                draft.productPNKeywords.isLoading = true
            })

            try {
                const response = await api.post('search_brand_models/',
                    { 'search_text': _kw, 'search_type': filterOptions.productPNKeywords.searchType },
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                //console.log(response.data)
                newKeywordMap[_kw] = {...response.data}
                //console.log(newKeywordMap)
                setFilterOptions(draft => {
                    draft.productPNKeywords.keywordMap = {...newKeywordMap}
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to search brand/models"
                })
            } finally {
                setFilterOptions(draft => {
                    draft.productPNKeywords.isLoading = false
                })
            }
        }

        let isDeleted = false

        const keys = Object.keys(filterOptions.productPNKeywords.keywordMap)
        const keysToDelete = []
        for (const key of keys) {
            // Optional: check if the key is a direct property of the object
            if (filterOptions.productPNKeywords.keywordMap.hasOwnProperty(key)) {
                if (!filterOptions.productPNKeywords.selected.includes(key)) { // keyword removed from search also remove from map
                    keysToDelete.push(key)
                    isDeleted = true
                }
            }
        }
        keysToDelete.forEach(key => delete newKeywordMap[key])

        let isAdded = false

        for (const kw of filterOptions.productPNKeywords.selected) {
            //console.log(kw)
            if (!keys.includes(kw)) { // new keyword entered query it from server and add to map
                isAdded = true
                searchBrandModels(kw)
            }
        }

        //console.log("newKwMap", newKeywordMap)
        if (isDeleted && !isAdded) {
            setFilterOptions(draft => {
                draft.productPNKeywords.keywordMap = {...newKeywordMap}
            })
        }

        setFilterOptions(draft => {
            draft.productPNKeywords.isLoading2 = false
        })

        return () => {
            ourRequest.cancel()
        }
        
    }, [filterOptions.productPNKeywords.selected])

    useEffect(() => {
        //console.log("kwMap", filterOptions.productPNKeywords.keywordMap)
    }, [filterOptions.productPNKeywords.keywordMap])

    /*** FETCH DATA ***/
    useFetchProductModels(appState, appDispatch, setProductModelState, filterOptions, setFilterOptions)
    useFetchBrands(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchCategories(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchCountries(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchRegions(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchDealers(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchContactTags(appState, appDispatch, filterOptions, setFilterOptions) // DataFetcher
    useFetchMessageStates(appState, appDispatch, setMessageStatesState, filterOptions, setFilterOptions)

    function createSegments(msgContent, indices) {
        const segments = []
        let lastIndex = 0
        let segmentIndex = 1

        for (const [start, end] of indices) {
            if (start > lastIndex) {
                segments.push({
                    content: msgContent.slice(lastIndex !== 0 ? lastIndex-1 : lastIndex, start),
                    color: '#FAF7F0',
                    idx: -1,
                })
            }

            segments.push({
                content: msgContent.slice(start, end),
                color: '#F8B501',
                idx: segmentIndex++,
            })
        
            lastIndex = end + 1
        }

        if (lastIndex < msgContent.length) {
            segments.push({
                content: msgContent.slice(lastIndex-1),
                color: '#FAF7F0',
                idx: -1,
            })
        }

        return segments
    }

    function findIndices(msgContent) {
        let allKeywords = []

        for (const keyword of filterOptions.messageKeywords.selected) {
            if (keyword.includes("&")) {
                allKeywords = allKeywords.concat(keyword.split("&").map(item => item.trim().toLowerCase()))
            } else {
                allKeywords.push(keyword.trim().toLowerCase())
                //allKeywords = allKeywords.concat(keyword.split(" ").map(item => item.trim().toLowerCase()))
            }
        }

        allKeywords = [...new Set(allKeywords)]  // to remove duplicates

        const msgContentLower = msgContent.toLowerCase()

        const indices = []

        for (const word of allKeywords) {
            let startIndex = 0
            
            while (startIndex !== -1) {
                startIndex = msgContentLower.indexOf(word, startIndex)

                if (startIndex !== -1) {
                    const endIndex = startIndex + word.length
                    indices.push([startIndex, endIndex])
                    startIndex += word.length
                }
            }
        }

        indices.sort(function(a, b) { return a[0] - b[0]})

        return indices
    }

    function toggleViewMsg(key) {
        const index = dataSource.messages.findIndex((item) => key === item.key)
        const val = dataSource.messages[index].showFullMsg
        setDataSource(draft => {
            draft.messages[index].showFullMsg = !val
        })
    }

    function viewLessMsg(record) {
        const msgContent = record.message.content
        const msgLength = msgContent.length

        if (record.showFullMsg && msgLength > MAX_MSG_LEN) { 
            return (
                <span className='link-btn-style'
                    onClick={(e) => {
                        toggleViewMsg(record.key)
                    }}>
                    {"view less"}
                </span>
            )
        }
        return ""
    }

    function openMessageModel(record) {
        const msgContent = getContactInfoAppendedMessageContent(record)
        setGenericWarningData(draft => {
            draft.title = msgContent
            draft.showNLPResult = appState.user.role === "DATA MANAGER"
            draft.bmList = record.bm_list
            draft.modal.isOpen = true
        })
    }

    function highlightMessage(record) {
        const msgContent = record.message.content
        const msgLength = msgContent.length

        if (!record.showFullMsg && msgLength > MAX_MSG_LEN) {
            const croppedContent = cropText(msgContent, MAX_MSG_LEN/2)
            return (
                <>
                    <div className="long-text-without-spaces-long-msg" onClick={() => openMessageModel(record)}>
                        {croppedContent}
                    </div>
                    <span className='link-btn-style'
                        onClick={(e) => {
                            toggleViewMsg(record.key)
                        }}>
                        {"view more"}
                    </span>
                </>
            )
        }

        const indices = findIndices(msgContent)
        if (indices.length === 0) {
            return (
                <>
                    <p className="long-text-without-spaces-long-msg" onClick={() => openMessageModel(record)}>
                        {msgContent}
                    </p>
                    {viewLessMsg(record)}
                </>
            )
        }

        const segments = createSegments(msgContent, indices)
        //console.log(segments)

        return (
            <p className="long-text-without-spaces-long-msg" onClick={() => openMessageModel(record)}>
                {segments.map((segment, index) => (
                    <span key={index}>
                        {segment.idx === -1 ? 
                            <span>{segment.content}</span>
                        :
                            <span style={{backgroundColor: segment.color}}>
                                {segment.content + " "}
                            </span>
                        }
                    </span>
                ))}
                {viewLessMsg(record)}
            </p>
        )
    }

    function toggleViewGrp(key) {
        const index = dataSource.messages.findIndex((item) => key === item.key)
        const val = dataSource.messages[index].showAllGroups
        setDataSource(draft => {
            draft.messages[index].showAllGroups = !val
        })
    }

    function groupsOrUsers(record) {
        const groupNames = record.group_names

        if (record.group_len > record.group_first_len) {
            const content = record.showAllGroups ? groupNames : cropText(groupNames, record.group_first_len)
            const displayText = record.showAllGroups ? "view less" : "view more"
            return (
                <div className="long-text-without-spaces-long">
                    {content}
                    <span className='link-btn-style'
                        onClick={(e) => {
                            toggleViewGrp(record.key)
                        }}>
                        {displayText}
                    </span>
                </div>
            )
        } else {
            return (
                <p className="long-text-without-spaces-long">
                    {groupNames}
                </p>
            )
        }
    }

    function toggleViewContactTags(key) {
        const index = dataSource.messages.findIndex((item) => key === item.key)
        const val = dataSource.messages[index].showAllContactTags
        setDataSource(draft => {
            draft.messages[index].showAllContactTags = !val
        })
    }

    const [assignTagData, setAssignTagData] = useImmer({ isLoading: false, recordKey: -1, contactId: -1, actionType: '', tagName: '', requestCount: 0 })
    const [contagTagsDropDownData, setContagTagsDropDownData] = useImmer({requestCount: 0, })

    function unAsssignTag(tagName, record) {
        setAssignTagData(draft => {
            draft.recordKey = record.message.id
            draft.contactId = record.vendor.id
            draft.actionType = "unAssign"
            draft.tagName = tagName
            draft.requestCount++
        })
    }

    function contactTags(record) {
        if (!("vendor_actions" in record)) {
            return ""
        }
        const vendorActions = record.vendor_actions

        let tags = []
        vendorActions.forEach(item => {
            if (appState.user.role === "DATA MANAGER") {
                if (item['action'] === "TAG") {
                    tags.push(item['vendor_tag'])
                }
            } else {
                if (item['action'] === "TAG" && item['custom_user__full_name'] === appState.user.username) {
                    tags.push(item['vendor_tag'])
                }
            }
        })
        
        if (appState.user.role === "DATA MANAGER") {
            tags = [...new Set(tags)]
        }
        
        const numTags = tags.length

        const contactTags = <ContactTags
                                record={record}
                                dataSource={dataSource}
                                setDataSource={setDataSource}
                                filterOptions={filterOptions}
                                setFilterOptions={setFilterOptions}
                                assignTagData={assignTagData}
                                setAssignTagData={setAssignTagData}
                                contagTagsDropDownData={contagTagsDropDownData}
                                setContagTagsDropDownData={setContagTagsDropDownData}
                            />

        if (numTags === 0) {
            return (
                <div>
                    {appState.user.role === "DATA MANAGER" ? <p>{"No tag"}</p> :
                    contactTags}
                </div>
            )
        } else if (numTags === 1) {
            const tag = tags[0]
            return (
                <>
                    <p className="long-text-without-spaces-long">
                        <AntTagWithTooltip text={tag} record={record} unAsssignTag={unAsssignTag} closable={appState.user.role !== "DATA MANAGER"}/>
                    </p>
                    {appState.user.role === "DATA MANAGER" ? "" :
                    contactTags}
                </>
            )
        } else {
            const tag = record.showAllContactTags ? tags : [tags[0]]
            const displayText = record.showAllContactTags ? "view less" : "view more"
            return (
                <>
                    <div className="long-text-without-spaces-long">
                        {tag.map((_tag) => {
                            return (
                                <AntTagWithTooltip key={_tag} text={_tag} record={record} unAsssignTag={unAsssignTag} closable={appState.user.role !== "DATA MANAGER"}/>
                            )
                        })}
                        <span className='link-btn-style'
                            onClick={(e) => {
                                toggleViewContactTags(record.key)
                            }}>
                            {displayText}
                        </span>
                    </div>
                    {appState.user.role === "DATA MANAGER" ? "" :
                    contactTags}
                </>
            )
        }
    }

    function getLastReachOut(record) {
        if (!("reach_outs" in record)) {
            return null
        }
        if (record.reach_outs !== null && record.reach_outs.length > 0) {
            return record.reach_outs[0]
        }
        return null
    }

    function isLastReachOutClose(record) {
        const last_reach_out = getLastReachOut(record)
        if (last_reach_out !== null) {
            if (isDateNew(last_reach_out.message_time, 24 * 60 * 60 * 1000)) {
                return true
            }
            return false
        }
        return false
    }

    function getAllReachOuts(record) {
        if (record.reach_outs !== null && record.reach_outs.length > 0) {
            return (
                <div>
                    {record.reach_outs.map((item, index) => (
                        <div key={4*index}>
                            <div key={4*index+1}>{getLocalTime(item.message_time, false)}</div>
                            <div key={4*index+2}>{item.custom_user_name}</div>
                            <div key={4*index+3}>{item.is_group ? item.chat_name : "DM"}</div>
                            <br />
                        </div>
                    ))}
                </div>
            )
        }
        return <div>-</div>
    }

    function getLastReachOutDiv(record) {
        const last_reach_out = getLastReachOut(record)
        if (last_reach_out !== null) {
            let textColor = "black"
            if (isLastReachOutClose(record)) {
                textColor = "#ff7875"
            }
            return (
                <Tooltip title={getAllReachOuts(record)} placement="bottom">
                    <div className="long-text-without-spaces-small" style={{ color: textColor, }}>
                        <div>{getLocalTime(last_reach_out.message_time, false)}</div>
                        <div>{last_reach_out.custom_user_name}</div>
                        <div>{last_reach_out.is_group ? last_reach_out.chat_name : "DM"}</div>
                    </div>
                </Tooltip>
            )
        }
        return <div></div>
    }

    function getSourceDiv(record) {
        if (record.message.source === "WHATSAPP") {
            return (
                <Tooltip title={"WhatsApp"} placement="bottom">
                    <WhatsAppOutlined
                        style={{fontSize: '20px', borderRadius: '9px', color:'green'}}
                        size='small' />
                </Tooltip>
            )
        } else if (record.message.source === "HANDELOT") {
            return (
                <Tooltip title={"Handelot"} placement="bottom">
                    <img src={handelot_logo} alt="handelot_logo" style={{width:'24px', height:'24px', borderRadius: '12px'}}/>
                </Tooltip>
            )
        }
    }

    useEffect(() => {
        async function setSpammer() {
            if (spammerData.spammerId === null || spammerData.requestCount === 0) return
            console.log(spammerData)
            try {
                const response = await api.patch(
                    `update_vendor/${spammerData.spammerId}/`,
                    {'is_spammer': spammerData.isSpammer},
                    appState.token.post_config
                )
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to update vendor!"
                })
            } finally {
                setFilterOptions(draft => {
                    draft.requestCount++
                })
            }
        }
        setSpammer()
    }, [spammerData.requestCount])

    function onIsSpammerChange(record, is_spammer) {
        const index = dataSource.messages.findIndex((item) => record.key === item.key)
        setDataSource(draft => {
            draft.messages[index].vendor.is_spammer = is_spammer
        })
        setSpammerData(draft => {
            draft.spammerId = record.vendor.id
            draft.isSpammer = is_spammer
            draft.requestCount++
        })
    }

    function onNavLivkClick(key) {
        const index = dataSource.messages.findIndex((item) => key === item.key)
        const messages = dataSource.messages.map((row) => {
            return {
                ...row,
                'isNavLivkClicked': false
            }
        })
        messages[index].isNavLivkClicked = true
        setDataSource(draft => {
            draft.messages = messages
        })
    }

    function navLinkCSS(key) {
        const index = dataSource.messages.findIndex((item) => key === item.key)
        if (dataSource.messages[index].isNavLivkClicked) {
            return "nav-link-clicked"
        } else {
            return "nav-link"
        }
    }

    function extractUPCsAndGetProductInfo(record) {
        setFromUPCToProductData(draft => {
            draft.content = record.message.content
            draft.msgId = record.message.id
            draft.modal.isOpen = true
        })
    }

    function exportMessageToOffers(record) {
        const msgContent = record ? getContactInfoAppendedMessageContent(record) : ""
        setMessageContentData(draft => {
            draft.title = msgContent
            draft.content = record ? record.message.content : ""
            draft.msgId = record ? record.message.id : -1
            draft.modal.isOpen = true
        })
    }

    const columns = [
        {
            
            title: dataSource.currentTable === "Messages" ? 'Msg Time' : 'Last Msg Time',
            width: dataSource.currentTable === "Messages" ? 70 : 140,
            //dataIndex: ['message', 'message_time'],
            key: 'msg_time',
            render: (_, record) => {
                const msg_time = getLocalTime(record.message.message_time, false)
                const timeP = <p className='message-id'>
                                {msg_time.split(",")[0]}{msg_time.split(",")[1]}
                              </p>
                return (
                    <>{timeP}</>
                    /*appState.user.role === "SALESMAN" || appState.user.role === "GUEST" ? <>{timeP}</> :
                    <Tooltip title="Open Deals in New Tab" placement="bottom">
                        <NavLink to={`/message/${record.message.id}`} target="_blank" rel="noopener noreferrer"
                                onClick={() => onNavLivkClick(record.key)} className={navLinkCSS(record.key)}>
                            {timeP}
                        </NavLink>
                    </Tooltip>*/
                )
            }
        },
        {
            title: 'Msg Id',
            width: 70,
            dataIndex: ['message', 'id'],
            key: 'msg_id',
        },
        {
            title: <Tooltip title={"Msg Actions"} placement="bottom">MA</Tooltip>,
            width: 50,
            align: 'center',
            render: (_, record) => {
                return (
                    "message_actions" in record ?
                        <MessageActions
                            record={record}
                            dataSource={dataSource}
                            setDataSource={setDataSource}
                        />
                    : ""
                )
            }
        },
        {
            title: 'Msg Type',
            width: 70,
            dataIndex: ['message_type'],
            key: 'msg_type',
        },
        {
            title:
            <Space>
                <div>{dataSource.currentTable === "Messages" ? 'Msg Content' : 'Last Msg Content'}</div>
                {appState.user.role === "OTHER" || appState.user.role === "GUEST" || appState.user.role === "SALESMAN" ? "" :
                <Tooltip
                    placement="right"
                    title="Create Custom Message"
                >
                    <Button style={{marginLeft:"10px", backgroundColor:"#F8B501"}}
                        onClick={() => exportMessageToOffers(undefined)} icon={<PicLeftOutlined/>} />
                </Tooltip>}
            </Space>
            ,
            //dataIndex: ['message', 'content'],
            key: 'content',
            render: (_, record) => {
                return (
                    appState.user.role === "GUEST" ?
                        <p className='long-text-without-spaces-long-msg2' onClick={() => openMessageModel(record)}>
                            {record.message.content}
                        </p>
                    :
                        <Space direction='horizontal'>
                            {"receivers" in record ?
                            <Space direction='vertical'>
                                {<Tooltip
                                    placement="leftTop"
                                    title="Find Product Info from UPCs"
                                >
                                    <TableOutlined
                                        style={{fontSize: '21px', color:'#F8B501', cursor:'pointer'}}
                                        onClick={() => extractUPCsAndGetProductInfo(record)}
                                    />
                                </Tooltip>}
                                {<Tooltip
                                    placement="leftTop"
                                    title="Export Message to Offers"
                                >
                                    <ExportOutlined
                                        style={{fontSize: '21px', color:'#ff7875', cursor:'pointer'}}
                                        onClick={() => exportMessageToOffers(record)}
                                    />
                                </Tooltip>}
                            </Space>
                            : ""}
                            <Space direction='vertical' size={"small"}>
                                {highlightMessage(record)}
                            </Space>
                        </Space>
                )
            }
        },
        {
            title: 'Groups/Users',
            width: 150,
            //dataIndex: ['group_names'],
            key: 'groups',
            render: (_, record) => {
                return (
                    <div>{groupsOrUsers(record)}</div>
                )
            }
        },
        {
            title: 'Contact Name',
            width: dataSource.currentTable === "Messages" ? 150 : 250,
            //dataIndex: ['vendor', 'full_name'],
            key: 'contact_name',
            render: (_, record) => {
                return (
                    "vendor" in record ?
                        <Tooltip title="Open Contact in New Tab" placement="bottom">
                            <NavLink to={`/contact/${record.vendor.id}`} target="_blank" >
                                {record.vendor.full_name}
                            </NavLink>
                        </Tooltip>
                    : ""
                )
            }
        },
        {
            title: 'Contact Tags',
            width: 160,
            key: 'contact_tags',
            render: (_, record) => {
                return (
                    <div>{contactTags(record)}</div>
                )
            }
        },
        {
            title: <Tooltip title={"Contact Actions"} placement="bottom">CA</Tooltip>,
            width: 50,
            align: 'center',
            render: (_, record) => {
                return (
                    "vendor" in record ?
                    <ContactActions
                        record={record}
                        dataSource={dataSource}
                        setDataSource={setDataSource}
                    />
                    : ""
                )
            }
        },
        {
            title: 'From',
            width: 150,
            dataIndex: ['vendor', 'country_names_from_area_code'],
            key: 'contact_from',
        },
        {
            title: 'Phone',
            width: 120,
            //sorter: true,
            //dataIndex: ['vendor', 'phone_number'],
            key: 'contact_phone',
            render: (_, record) => {
                return (
                    "vendor" in record ?
                    <Space direction="horizontal" size="small">
                        <Popconfirm
                            title="Are you sure?"
                            //description=""
                            onConfirm={(e) => { onIsSpammerChange(record, !record.vendor.is_spammer) }}
                            //onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tooltip title={record.vendor.is_spammer ? 'Unmark as Spammer' : 'Mark as Spammer'} placement="bottom">
                                <Checkbox
                                    key={"s"+record.id}
                                    checked={record.vendor.is_spammer}
                                />
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip title="Click to Copy Contact Number" placement="bottom">
                            <p 
                                className='vendor-style'
                                onClick={(e) => {
                                    //sendMessage(props.record.vendor.phone_number, "")
                                    copyToClipboard(record.vendor.phone_number)
                                    messageApi.open({
                                        type: 'success',
                                        content: 'Copied!',
                                        //className: 'no-trans',
                                        duration: '1.',
                                    })
                                }}>
                                {record.vendor.phone_number}
                            </p>
                        </Tooltip>
                    </Space>
                    : ""
                )
            }
        },
        {
            title: 'Last Reach Out',
            width: 150,
            //dataIndex: ['reach_outs'],
            key: 'last_reach_out',
            render: (_, record) => {
                return ( getLastReachOutDiv(record) )
            }
        },
        {
            title: <Tooltip title={"Source"} placement="bottom">Src</Tooltip>,
            width: 25,
            //dataIndex: ['reach_outs'],
            align: 'center',
            key: 'source',
            render: (_, record) => {
                return ( getSourceDiv(record) )
            }
        },
    ]

    if (appState.user.role === "DATA MANAGER-") {
        const state_column =
        {
            title: 'Msg State',
            width: 150,
            dataIndex: ['message', 'state'],
            key: 'message_state',
        }

        columns.push(state_column)
    }

    const contactIndices = [0, 4, 6, 7, 8, 9, 10, 11]
    const contactColumns = contactIndices.map(index => columns[index])

    const guestIndices = [0, 1, 3, 4]
    const guestColumns = guestIndices.map(index => columns[index])

    function onTableChangeClicked() {
        const newTableName = dataSource.currentTable === "Messages" ? "Contacts" : "Messages"
        setDataSource(draft => {
            draft.pagination.current = 1
            draft.currentTable = newTableName
        })
    }

    /*** Table Related Functions ***/
    function handleTableChange(pagination, filters, sorter) {
        setDataSource(draft => {
            draft.pagination.current = pagination.current
            draft.pagination.pageSize = pagination.pageSize
        })
        setSortingOptions(draft => {
            draft.columnName = sorter.column?.title
            draft.sortType = sorter.order
            draft.requestCount++
        })
    }

    const tableLoading = {
        spinning: dataSource.isLoading,
        indicator: <Spin size="large" />,
    }

    if (!appState.loggedIn) return <Login />

    return (
        <div className='App'>
            {messageContextHolder}
            <SideMenu connected={props.connected}/>
            <Space size="small" direction='horizontal' align='start' style={{paddingLeft: 15, paddingTop: 10}}>
                <TableFilters
                    tableName="Message"
                    disabled={dataSource.isLoading}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    dataSource={dataSource}
                    setDataSource={setDataSource}
                    messageId={messageId}/>
                <div direction="vertical" size="small">
                    <MessagesSearchBar
                        disabled={dataSource.isLoading}
                        filterOptions={filterOptions}
                        setFilterOptions={setFilterOptions}
                        dataSource={dataSource}
                        setDataSource={setDataSource}
                        onTableChangeClicked={onTableChangeClicked}/>
                    <GenericWarningModal
                        state={genericWarningData}
                        setDataSource={setDataSource}
                        setState={setGenericWarningData}
                        notDisplayOkButton={true}
                        displayCopyButton={true}
                    />
                    <MessageContentModal
                        state={messageContentData}
                        setDataSource={setDataSource}
                        setState={setMessageContentData}
                        notDisplayOkButton={true}
                        displayCopyButton={true}
                    />
                    <ExtractUPCsAndGetProductInfoModal
                        state={fromUPCToProductData}
                        setState={setFromUPCToProductData}
                    />
                    <div className="table-container">
                        <Table
                            loading={tableLoading}
                            style={{width: dataSource.currentTable === "Messages" ? 1644 : 1444, }}
                            className="messages-table"
                            bordered={{ border: 'none', }}
                            dataSource={dataSource.messages}
                            columns={appState.user.role === "GUEST" ? guestColumns : (dataSource.currentTable === "Messages" ? columns : contactColumns)}
                            onChange={handleTableChange}
                            pagination={dataSource.pagination}
                        />
                    </div>
                    <div style={{height: 164, }}></div>
                </div>
            </Space>
        </div>
    )
}

export default MessagesTable